.plan-review-request-list-wrap {
    .plan-review-request-mass-edit-wrap{
      display: flex;
      position: relative;
      top: 20px;
      gap: 30px;
      margin-bottom: -10px;

      .checkbox-container {
        display: flex;
        justify-content: center;
        width: 6%;
        > input {
          accent-color: $main;
        }
      }
  
      input {
        margin: 5px;
        accent-color: $blue;
      }
  
      .action-button{
        cursor: pointer;
        > i {
          &.fa-pause {
            color: $text-yellow;
          }
          &.fa-play {
            color: $xevant-green;
          }
        }
      }
      
      .delete-button{
        color: $red;
      }
    }
  
    .data-table > table {
      > tbody > tr > td:first-child {
        width: 6%;

        > div > .checkbox-col > input {
          accent-color: $main;
        }
      }
      
      > tbody > tr > td.statusCell.rejected > div {
        background-color: $red;
      }
      > tbody > tr > td.statusCell.approved > div {
        background-color: $xevant-green;
      }
      > tbody > tr > td.statusCell.pending > div {
        background-color: $text-yellow;
      }
      .action-buttons{
        .action-button{
          
            &.delete-button {
             color: $red;
            }

            &.download-button {
              color: $blue;
            }

            &.disabled {
              pointer-events: none;
              opacity: 0.5;
            } 
        }
  
        .blank-space {
          width: 40px;
        }
      }
    }
  }
  
  .plan-review-request-swal-actions > button.swal2-styled {
    height: 35px;
    padding: 8px 16px;
  }
  
  .visible-button {
    visibility: visible;
  }