.dl-file-list {

  .data-table {
    .filename {
      padding: 10px 8%;
      text-align: left;
    }

    tr > td.statusCell {
      &.inprocess > div {
        background-color: $dark-blue;
      }

      &.forresolution > div {
        background-color: $text-yellow;
      }

      &.error > div {
        background-color: $text-red;
      }

      &.done > div {
        background-color: $xevant-green;
      }
    }

    > table > tbody > tr > td .action-buttons {
      align-items: center;
      display: flex;
      font-size: 16px;
      justify-content: flex-end;
      margin: -5px 0;
      padding-right: 35%;
    }
  }
}