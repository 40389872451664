.directories {
  margin-top: 80px;
  margin-left: 40px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 64px;

  li {
    min-width: 500px;
    background-color: $dark-blue;
    height: 100px;
    border-radius: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 200ms ease-in-out;

    &:hover {
      transform: scale(1.1);
    }

    a {
      color: white;
      font-size: 38px;
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 40px;

      span {
        font-size: 24px;
      }
    }
  }
}


.file-upload-header {
  display: flex;
  justify-content: center;
}

.file-upload-header-text {
  font-size: .8rem;
  background-color: $dark-blue;
  color: $gray;
  margin: 0;
  padding: 0;
  padding: 8px 16px 8px 16px;
  margin-bottom: 32px;
  text-align: center
}