.login {
  > .content {
    > .middle {
      .left-info {
        .left-content.unauthorized {
          display: flex;
          flex-direction: column;
          align-items: center;
        }
      }
    }
  }
}





