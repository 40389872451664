.border-wrap {
  border: 1px solid $dark-blue;
  padding: 50px 19px 30px;
  margin: 20px;
  margin: 44px 0;
  display: flex;
  flex-direction: column;
  border-radius: 5px;

  > h2 {
    margin: 0;
    margin-top: -65px;
    margin-bottom: 35px;
    margin-left: 11px;
    border: 1px solid $dark-blue;
    background-color: $white;
    padding: 3px 15px;
    font-size: 16px;
    font-weight: 500;
    align-self: flex-start;
    color: $dark-blue;
    border-radius: 5px;
  }

  .form-group {
    gap: 30px;
    justify-content: center;

    .form-input {
      flex:none;

      .input > i {
        margin-right:5px;
        margin-left: -23px;
        width: 18px;
        cursor: pointer;
      }
    }

    .input-label {
      font-weight:400;
    }

    .input-after {
      align-self: end;
      padding-bottom:9px;
      margin-left: -22px;
      font-size: 14px;
      color: $xevant-gray;
    }

    &.full-width-input {
      > .form-input {
        flex: 1;
      }
    }
  }
}

.form.settings-form {
  width: auto;
  margin-bottom: 50px;

  .form-split-wrap {
    gap: 30px;
  }
}


.input-wrap-margin {
  margin-bottom: 16px;
}

#input-span-padding {
  padding-bottom: 88px
}

.right-submit-button {
  padding: 8px 16px 8px 16px;
}