.page-header {
  align-items: center;
  background-color: $white;
  display: flex;
  justify-content: space-between;
  padding: 18px;
  position: fixed;
  top: 0;
  left: 264px;
  width: calc(100% - 264px);
  z-index: 1;

  .title-container {
    margin-right: auto;

    .title {
      font-size: 20px;
      font-weight: 700;
      color: #09304C;
      text-transform: capitalize;
    }
  }

  .actions-container {
    display: flex;
    gap: 8px;

    > #tableau-viz-actions--corners {
      display: flex;
      gap: 8px;

      > button {
        height: 24px;
        #alert-button {
          font-size: 12px;
        }
        &.standard {
          &.subscribe {
            font-size: 18px !important;
            padding: 4px 16px 8px 16px;
          }
          &.alert {
            font-size: 12px !important;
            gap: 5px !important;
          }
        }
      }
    }
  }
}
