.server-message {
  width: 480px;
  margin: auto;
  border-radius: 4px;
  overflow: hidden;
  box-shadow: 0px 2px 2px 1px rgba(10, 25, 39, 0.06);


  &.board-message {
    margin-top: 150px;
  }

  .top-bar {
    background-image: $gradient-blue;
    width: 100%;
    height: 6px;
  }

  > .message {
    padding: 80px 40px;
    font-size: 20px;
    font-weight: 300;
    text-align: center;
  }
}

.error-message {
  height: 60%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  .unauthorized {
    width: 10em;
    margin-bottom: 1.5em
  }

  h1 {
    margin: 10px 0;
    font-size: 2em;
    // text-shadow: 0px 0.25px, 0.25px 0px, 0.25px 0.25px;
  }

  h4 {
    padding: 0 25%;
    font-size: 1.2em;
    font-weight: normal;
    text-align: justify;
    margin: 0;
  }
}

