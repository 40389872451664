.loader {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  background-color: $blue;
  width: 100%;
  height: 100%;
  top: 0px;
  left: 0px;
  z-index: 1024;

  &.blurred {
    background:rgba(0, 0, 0, 0.2);
    filter: blur(4px);
  }
}

.cube-folding {
  width: 50px;
  height: 50px;
  display: inline-block;
  -moz-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  font-size: 0;
}

.cube-folding span {
  position: relative;
  width: 25px;
  height: 25px;
  -moz-transform: scale(1.1);
  -ms-transform: scale(1.1);
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
  display: inline-block;
}

.cube-folding span::before {
  content: '';
  background-color: white;
  position: absolute;
  left: 0;
  top: 0;
  display: block;
  width: 25px;
  height: 25px;
  -moz-transform-origin: 100% 100%;
  -ms-transform-origin: 100% 100%;
  -webkit-transform-origin: 100% 100%;
  transform-origin: 100% 100%;
  -moz-animation: folding 2.5s infinite linear both;
  -webkit-animation: folding 2.5s infinite linear both;
  animation: folding 2.5s infinite linear both;
}
.cube-folding .leaf2 {
  -moz-transform: rotateZ(90deg) scale(1.1);
  -ms-transform: rotateZ(90deg) scale(1.1);
  -webkit-transform: rotateZ(90deg) scale(1.1);
  transform: rotateZ(90deg) scale(1.1);
}
.cube-folding .leaf2::before {
  -moz-animation-delay: 0.3s;
  -webkit-animation-delay: 0.3s;
  animation-delay: 0.3s;
  background-color: #f2f2f2;
}
.cube-folding .leaf3 {
  -moz-transform: rotateZ(270deg) scale(1.1);
  -ms-transform: rotateZ(270deg) scale(1.1);
  -webkit-transform: rotateZ(270deg) scale(1.1);
  transform: rotateZ(270deg) scale(1.1);
}

.cube-folding .leaf3::before {
  -moz-animation-delay: 0.9s;
  -webkit-animation-delay: 0.9s;
  animation-delay: 0.9s;
  background-color: #f2f2f2;
}

.cube-folding .leaf4 {
  -moz-transform: rotateZ(180deg) scale(1.1);
  -ms-transform: rotateZ(180deg) scale(1.1);
  -webkit-transform: rotateZ(180deg) scale(1.1);
  transform: rotateZ(180deg) scale(1.1);
}

.cube-folding .leaf4::before {
  -moz-animation-delay: 0.6s;
  -webkit-animation-delay: 0.6s;
  animation-delay: 0.6s;
  background-color: #e6e6e6;
}

@-moz-keyframes folding {
  0%, 10% {
    -moz-transform: perspective(140px) rotateX(-180deg);
    transform: perspective(140px) rotateX(-180deg);
    opacity: 0;
  }
  25%, 75% {
    -moz-transform: perspective(140px) rotateX(0deg);
    transform: perspective(140px) rotateX(0deg);
    opacity: 1;
  }
  90%, 100% {
    -moz-transform: perspective(140px) rotateY(180deg);
    transform: perspective(140px) rotateY(180deg);
    opacity: 0;
  }
}
@-webkit-keyframes folding {
  0%, 10% {
    -webkit-transform: perspective(140px) rotateX(-180deg);
    transform: perspective(140px) rotateX(-180deg);
    opacity: 0;
  }
  25%, 75% {
    -webkit-transform: perspective(140px) rotateX(0deg);
    transform: perspective(140px) rotateX(0deg);
    opacity: 1;
  }
  90%, 100% {
    -webkit-transform: perspective(140px) rotateY(180deg);
    transform: perspective(140px) rotateY(180deg);
    opacity: 0;
  }
}
@keyframes folding {
  0%, 10% {
    -moz-transform: perspective(140px) rotateX(-180deg);
    -ms-transform: perspective(140px) rotateX(-180deg);
    -webkit-transform: perspective(140px) rotateX(-180deg);
    transform: perspective(140px) rotateX(-180deg);
    opacity: 0;
  }
  25%, 75% {
    -moz-transform: perspective(140px) rotateX(0deg);
    -ms-transform: perspective(140px) rotateX(0deg);
    -webkit-transform: perspective(140px) rotateX(0deg);
    transform: perspective(140px) rotateX(0deg);
    opacity: 1;
  }
  90%, 100% {
    -moz-transform: perspective(140px) rotateY(180deg);
    -ms-transform: perspective(140px) rotateY(180deg);
    -webkit-transform: perspective(140px) rotateY(180deg);
    transform: perspective(140px) rotateY(180deg);
    opacity: 0;
  }
}

.cube-wrapper {
  position: fixed;
  left: 50%;
  top: 50%;
  margin-top: -50px;
  margin-left: -50px;
  width: 100px;
  height: 100px;
  text-align: center;
  filter: blur(0px);
}

.cube-wrapper .loading {
  font-size: 12px;
  letter-spacing: 0.1em;
  display: block;
  color: white;
  position: relative;
  top: 25px;
  z-index: 2;
  -moz-animation: text 0.5s ease infinite alternate;
  -webkit-animation: text 0.5s ease infinite alternate;
  animation: text 0.5s ease infinite alternate;
}

@-moz-keyframes text {
  100% {
    top: 35px;
  }
}
@-webkit-keyframes text {
  100% {
    top: 35px;
  }
}
@keyframes text {
  100% {
    top: 35px;
  }
}
@-moz-keyframes shadow {
  100% {
    bottom: -18px;
    width: 100px;
  }
}
@-webkit-keyframes shadow {
  100% {
    bottom: -18px;
    width: 100px;
  }
}
@keyframes shadow {
  100% {
    bottom: -18px;
    width: 100px;
  }
}
