#tableauVizWrapAlertForm {
  opacity: 0 !important;
}

.alert-form {
  max-height: 95vh;
  overflow-y: scroll;
  padding: 32px;
  mask-image: linear-gradient(to bottom, transparent 0%, black 20px, black calc(100% - 20px), transparent 100%);
  -webkit-mask-image: linear-gradient(to bottom, transparent 0%, black 20px, black calc(100% - 20px), transparent 100%);


  form {
    width: 100%;

    .divider {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 20px;

      &.page-2 {
        margin-bottom: 40px;
      }

      span {
        font-size: 14px;
        font-weight: 600;
      }

      > hr {
        width: 365px;
        border-top: 1px solid $text-blue;
      }
    }

    > .toggle-alert-form {
      margin: 24px 0;
      display: flex;
      gap: 12px;
      font-size: 14px;

      .form-input {
        flex: 0;
        > .input.toggle > .toggle-input {
          width: 34px;
          height: 20px;

          &.active {
            background-color: $blue;

            > .toggler-inner {
              .toggler {
                background-color: $white;
              }
            }
          }
          > .toggler-inner {
            .toggler {
              background-color: $xevant-gray;
            }
          }
        }
      }
    }
  }

  h2 {
    color: $main;
    margin-top: 0;
    font-size: 18px;
    font-weight: 600;
  }

  .alert-filters-form {

    > form {
      width: 100%;

      > .form-group {
        margin-bottom: 24px;
      }
    }
  }

  .action-buttons {
    justify-content: start;
    margin: 0 !important;

    .grouped-buttons {
      display: flex;
      flex: 1;
      gap: 12px;

      > button {
        font-size: 12px;
        padding: 0;

        &.go-to-list {
          min-width: 75px;
          background-color: transparent;
          border: none;
          cursor: pointer;

          > .button-content {
            color: $main;
            background-color: transparent;

            &:hover {
              color: $hover-blue
            }
          }
        }
      }
    }
  }

  .alert-rules-form {

    > form > .form-group {
      margin-bottom: 24px;

      > .alert-prompt {
        padding: 4px 8px;
        border-radius: 5px;
        background-color: $blue;
        color: white;
        font-size: 14px;

        > span {
          font-weight: 600;
        }
      }

      > .form-input > .input.text.off {
        border-color: $xevant-gray;
      }

      > .apply-filters-toggle {
        display: flex;
        gap: 12px;
        font-size: 14px;

        > .form-input {
          flex: 0;

          > .input.toggle > .toggle-input {
            width: 34px;
            height: 20px;

            &.active {
              background-color: $blue;

              > .toggler-inner > .toggler {
                background-color: $white;
              }
            }

            > .toggler-inner > .toggler {
              background-color: $xevant-gray;
            }
          }
        }
      }

      > .form-input > .member-textarea.with-fake {
        background-color: transparent;

        textarea {
          height: 35px;
          padding: 4px 20px;
          color: transparent;
          caret-color: black;
          line-height: 27px;
          border: none;
          outline: none;

          ::placeholder {
            color: black;
          }
          &::-webkit-scrollbar {
            width: 10px; /* Adjust the width as needed */
            background-color: transparent; /* Transparent background for the track */
          }

          &::-webkit-scrollbar-thumb {
            background-color: $scroll-bg; /* Light gray color for the thumb */
            border: 1px solid $scroll-border; /* Slight border for the thumb */
            border-radius: 5px; /* Rounded corners for the thumb */
          }

          &::-webkit-scrollbar-thumb:hover {
            background-color: $scroll-hover; /* Slightly darker color on hover */
          }

          &::-webkit-scrollbar-track {
            background-color: transparent; /* Transparent background for the track */
          }

          &::-webkit-scrollbar-button {
            display: none; /* Hide the scroll buttons (up and down arrows) */
          }

          /* Hide the scrollbar corner (between vertical and horizontal scrollbars) */
          &::-webkit-scrollbar-corner {
            background-color: transparent;
          }
        }
      }

      > div.fake-textarea-wrap.form-input {
        position: absolute;
        width: 100%;

        .input-label {
          color: transparent;
        }

        div.input > .member-fake-textarea {
          height: 35px;
          padding: 4px 20px;
          flex: 1;
          font-size: 14px;
          outline: none;
          overflow: auto;
          line-height: 27px;
          word-break: break-word;

          .valid-email {
            padding: 4px 0;
            background-color: #F1F1F8;
          }
        }
      }
    }

    > form > .form-group.radio-group {
      display: flex;
      font-size: 14px;
      margin-bottom: 12px;

      > .radio-label {
        display: flex;
        align-items: center;
        width: 50%;

        > label {
          font-weight: 600;

          &.unchecked {
            color: $black;
          }

          &.disabled {
            color: $disabled-gray;
          }
        }

        > input[type='radio'] {
          margin: 0 5px 0 0;

          &.disabled {
            &::before {
              border: 1px solid $disabled-gray;
            }
          }

          &::before {
            width: 12px;
            height: 12px;
            padding:-1px 0 0 -1px;
            border-radius: 50%;
            background-color: $white;
            content: '';
            display: inline-block;
            box-shadow: 0 0 0 2px white inset;
            border: 1px solid $xevant-gray;
          }

          &:checked::before {
            width: 12px;
            height: 12px;
            border-radius: 50%;
            background-color: $main;
            content: '';
            display: inline-block;
            box-shadow: 0 0 0 2px white inset;
            border: 1px solid $main;
          }

          &:not(:checked) {
            width: 12px;
            height: 12px;
            border-radius: 50%;
            background-color: transparent;
            content: '';
            display: inline-block;
            border: 1px solid $xevant-gray;
          }

          &.red-border:not(:checked)::before {
            width: 12px;
            height: 12px;
            border-radius: 50%;
            background-color: transparent;
            content: '';
            display: inline-block;
            box-shadow: 0 0 0 2px white inset;
            border: 1px solid $red;
          }
        }

      }

    }
  }
}

