.release-note-wrapper {
  border: 1px solid $blue;
  display: flex;
  flex-direction: column;
  height: 500px;

  .release-note-editor {
    background-color: $white;
    padding: 12px;
  }
}

.public-DraftStyleDefault-block {
  margin: 0;
}

.rdw-embedded-modal, .rdw-link-modal {
  height: auto;
}
