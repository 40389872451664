.resource-cards-wrapper {
  display: flex;
  flex-wrap: wrap;
  gap: 2.5%;
  grid-row-gap: 60px;
  justify-content: space-between;
  padding-top: 32px;

  .resource-card {
    background-color: white;
    border-radius: 5px;
    box-shadow: 0 0 20px -7px rgba(0,0,0,0.25);
    cursor: pointer;
    min-height: 250px;
    max-width: 290px;
    min-width:230px;
    transition: 0.3s;
    width: 18%;

    &:hover {
      box-shadow: 0 0 20px -5px rgba(0,0,0,0.4);
    }

    .resource-image {
      height: 150px;

      img {
        height: 100%;
        object-fit: cover;
        width: 100%;
      }
    }

    .resource-info {
      border-top: 2px solid $text-blue;
      padding: 6px 12px;

      h2 {
        color: $text-blue;
        font-size: 16px;
        font-weight: 600;
        margin: 0;
        margin-bottom:5px;
      }

      p {
        color: #585858;
        font-size: 12px;
        text-align: justify;
      }
    }
  }
}