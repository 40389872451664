.modal-backdrop {
  > .modal {
    height: auto;
    overflow: hidden;

    > .modal-close {
      color: $gray;
    }

    > .header {
      font-size: 14px;
      font-weight: 600;
      background-color: $blue;
      color: $gray;
    }

    > .content {
      font-weight: 400;
      padding: 0 20px;
      margin: 10px 0 20px 0;
      min-height: 50px;

      .content-message {
        padding: 12px 0 20px;

        &.centered {
          padding: 20px 0;
        }
        > .timer {
          font-size: 14px;
          font-weight: 600;
          color: $red;
        }
      }
    }
  }

  .action-buttons {
    display: flex;
    justify-content: flex-end;
    gap: 12px;
    margin: 20px 0;

    &.notice {
      justify-content: center;

      > button {
        background-color: $blue;
        color: $white;

        &.secondary {
          &:active {
            color: $active-blue !important;
            border: 1px solid $active-blue !important;
          }
        }
      }
    }
  }
}
