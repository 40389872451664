.user-form{
  width: 50%;
  margin: 0 auto;

  > .form-group {
    gap:40px;
    margin-bottom: 30px;

    > button {
      font-size: 14px;
      font-weight: 400;
      width: 100%;
    }

    > .form-input {
      > .input.disabled {
        border: 1px solid $disabled-gray;
        color: $text-gray;
        cursor: no-drop;
      }
    }
  }

  > .form-submit {
    > button {
      font-size: 14px;
      font-weight: 400;
      width: 100%;
    }
  }
}

.user-actions-popup{
  text-align: left;
  font-size:14px;

  >h2{
    text-align: center;
  }

  .user-actions-content{
    display:flex;

    >.left{
      flex:1;
    }
    >.right{
      flex:1;

      .resend-activation-button{
        >i{
          margin-right:7px;
        }
      }

      .status-inactive-div{
        margin-bottom: 10px;

        i{
          color:$dark-blue;
          cursor:pointer;
        }
      }

      .status-lock-div{
        display:flex;
        justify-content: space-between;
        // width:90%;
        margin-bottom:10px;
        align-items: center;

        >div>i{
          margin: 2px 5px 0 5px;
          cursor:pointer;

          &.fa-unlock{
            // color:$xevant-green;
            color: #34c158;
          }
          &.fa-lock{
            color:$red;
          }
        }

        .input.toggle{
          width:auto;
          // align-items: end;

          >.toggle-input{
            transform: scale(.8);
            background-color: $red;

            .toggler::before{
              font-weight: 900;
              font-size:16px;
              margin: 5.5px;
              line-height:1.6em;
              color: $red;
            }

            &.active{
              background-color: #34c158;

              .toggler::before{
                color: #34c158;
              }
            }
          }
        }
      }

      .datalogic-access-div{
        margin-bottom:10px;

        >.form-input {
          display:flex;
          justify-content: space-between;

          >.input-label{
            padding-left: initial;
            font-size: 14px;
            font-weight: initial;
            color: initial;
          }

          >.input.toggle{
            width:auto;
            align-items: end;

            >.toggle-input{
              background-color: #eee;
              transform:scale(.8);

              &.active{
                background-color: #34c158;
              }
            }
          }
        }
      }
    }
  }
}