.login, .resources {
  padding: 10px;
  background-size: cover;

  .logout {
    display: flex;
    justify-content: flex-end;

    > button{
      font-size: 10px;
    }
  }

  > .content {
    max-width: 1010px;
    margin: auto;

    .password-validation-extension {
      height: 750px !important;
    }

    .login-container-height {
      height: 700px !important;
    }

    > .middle {
      display: flex;
      background-color: rgba(255,255,255,0.9);
      box-shadow: 0px 0px 5px rgba(192, 192, 192, 0.75);
      height: 650px;
      padding: 8px;

      > .message {
        flex: 1;
        padding: 20px 80px 0px 0px;

        p {
          font-size: 48px;
          font-weight: 200;
          color: $white;
        }
      }

      h1 {
        color: $blue;
        font-size: 32px;
        font-weight:700px;
      }

      h2 {
        color: $blue;
        font-size: 28px;
        font-weight: 700px;
      }

      h3 {
        color: #434343;
        font-size:24px;
        font-weight: 400;
      }

      p{
        font-size: 12px;

        &.big-text{
          font-size: 16px;
          text-align: justify;
          line-height: 1.5rem;

          &.margin-bottom {
            margin-bottom: 10px;
          }
          span.highlight-blue {
            color: $blue
          }

        }

        .text-action {
          color: $blue;
          font-weight: bold;
          text-decoration: underline;

          &:hover{
            cursor:pointer;
          }
        }
      }

      .form-footer.login-footer {
        position: absolute;
        bottom: 30px;
        left: 0;
        width: 100%;
      }

      .copyright{
        margin: 5px 0;
        font-size: 10px;
        text-align: center;
      }

      .left-info {
        &.forgot-password {
          > .left-content {
            padding: 120px 70px;
          }
        }

        width: 500px;

        .login-logo-left {
          display: flex;
          justify-content: left;
          margin: 20px;

          img {
            max-height: 80px;
            max-width: 90%;
          }
        }

        .left-content {
          height: 470px;

          &.invalid-left-content {
            padding: 100px 70px;
          }
        }
      }

      .right-form {
        background-color: $white;
        padding: 25px 60px;
        width: 500px;
        position: relative;

        &.invalid-right-form {
          padding: 25px 50px;
        }

        > .login-logo-center {
          display: flex;
          justify-content: center;
          margin-bottom: 44px;
          margin-top: 20px;

          img {
            max-width: 100%;
            max-height: 100px;
          }
        }

        form {
          margin-top: 40px;
          height: 375px;

          .form-group {
            margin-bottom: 30px;
          }

          .form-submit {

            &.login-submit {
              margin-top: 20px;
            }

            &.has-error {
              margin-top: 0;
            }
          }

          > .form-errors {
            margin: 10px 0;
            // display: flex;

            > .form-error {
              // flex: 1;
              padding: 10px;
            }
          }

          .login-password > i {
            margin: 0 10px 0 -28px;
            cursor: pointer;
          }

          > .form-bottom {
            margin: 13px 0;
            font-weight: 600;
            font-size: 12px;
            text-align: center;

            a {
              color: $blue;
              font-weight: 400;

              &:hover {
                color: $hover-blue;
                text-decoration: underline;
              }
            }
          }
        }
      }
    }
  }
}

.login-button {
  background-color: $blue;
  border-radius: 5px;
  font-size: 16px;
  font-weight: 600;
  height: 35px;
  margin-top: 30px;
  width: 128px;

  &.full-width {
    width: 100%;
  }

  &.primary {
    &:hover {
      opacity: .9;
    }

    &:active {
      background-color: $active-blue;
    }
  }

  &.back{
    border: 1px solid $text-blue;
    color: $text-blue;
    background-color: transparent;
    padding: 0;
  }

  &.forgot-password {
    display: initial;
  }
}