@mixin linkStyle {
  display: flex;
  flex-direction: column;
  align-items: inherit;
  justify-content: inherit;
  width: inherit;
  height: inherit;
  font-weight: bold;
  gap: 12px;

  > i {
    font-size: 52px;
    font-size: 40px;
  }

  > span {
    font-size: 16px;
    font-weight: 400;
    text-align: center;
  }
}

section {
  &.maintenance {
    display: flex;
    flex-direction: column;
    align-items: center;
    border: 1px solid $dark-blue;
    border-radius: 5px;
    padding: 37px 30px;
    margin: 53px 0;
    margin-top: 100px;

    &:last-child {
      margin-bottom: 0;
    }

    > h1 {
      margin: -60px 0 17px 0;
      background-color: $white;
      font-weight: 600;
      font-size: 20px;
      color: $dark-blue;
      border: 1px solid $dark-blue;
      border-radius: 5px;
      align-self: flex-start;
      padding: 13px 22px;
    }

    > ul {
      display: flex;

      > li {
        width: 146px;
        height: 125px;
        margin: 0 30px;
        padding: 0 10px;
        border-radius: 20px;
        border: 1px solid $dark-blue;
        // box-shadow: 0px 0px 5px 0px rgba(27,90,136,.5);
        display: flex;
        justify-content: center;
        align-items: center;
        transition: all 200ms ease-in-out;

        &:active {
          background-color: $active-blue;
          border: 1px solid $active-blue;
        }

        &:hover {
          transform: scale(1.1);
        }

        > a {
          @include linkStyle;
        }

        > div.sync-plan-review {
          @include linkStyle;
          color: $blue;

          :hover {
            cursor: pointer;
          }
        }
      }
    }
  }
}