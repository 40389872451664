.activation-status {
  display: flex;
  font-size: 12px;
  justify-content: center;
  text-align: center;

  > p {
    border-radius: 10px;
    color: $white;
    padding: 2px 12px;
    width: fit-content;

    &.not-activated {
      background-color: $red;
    }

    &.partial {
      background-color: $text-yellow;
    }

    &.onboarded {
      background-color: $blue;
    }
  }
}