.tableau-viz-contain {
  display: block;
  margin: auto;
  margin-top: 60px;
  position: relative;

  h1 {
    font-size: 18px;
  }

  #tableau-viz-actions {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 24px;
    visibility: hidden;

    > #tableau-viz-actions--left {
      align-items: center;
      color: $xevant-gray;
      display: flex;
      flex: auto;
      font-size: 14px;

      > .form-input {
        margin-right: 16px;
        flex: 0;

        > .input {
          &.toggle {
            > .toggle-input {
              height: 16px;
              width: 32px;
              transition: width 0.5s ease-in-out;

              &.active {
                background-color: $blue;

                > .scorecard-toggler-inner, .scorecard-toggler-inner--active {
                  .toggler {
                    background-color: $white;
                    left: 55%;
                  }
                }
              }

              > .scorecard-toggler-inner, .scorecard-toggler-inner--active {
                .toggler {
                  background-color: $xevant-gray;
                  height: 12px;
                  width: 12px;
                  top: 1px;
                  left: 1px;
                  bottom: 1px;
                }
              }
            }
          }
        }
      }
    }

    > #tableau-viz-actions--corners {
      display: flex;
      gap: 8px;

      > button {
        #alert-button {
          font-size: 12px;
        }
        &.standard {
          &.subscribe {
            font-size: 18px !important;
            padding: 4px 16px 8px 16px;
          }
          &.alert {
            font-size: 12px !important;
            gap: 5px !important;
          }
        }
      }
    }
  }

  #tableau-viz-tabs-contain {
    display: flex;
    margin: 0 auto;

    @media screen and (max-width: 1920px) {
      margin: 0;
    }

    &.executive-dashboard{
      margin: 0;
    }

    #tableau-viz-tabs {
      display: flex;
      margin-bottom: 5px;
      overflow-x: hidden;

      .tableau-viz-tab {
        &.button {
          background-color: $light-gray;
          border: none;
          color: $blue;
          flex: none;
          font-family: 'Roboto', sans-serif;
          font-size: 12px;
          margin: 0 1px;
          min-width: 0;
          padding: 8px 16px;
          text-transform: none;

          &.active {
            background-color: $blue;
            color: #fafafa;
          }
        }
      }
    }

    .tableau-viz-tab-nav {
      background-color: $blue;
      color: $white;
      min-width: 0;

      &:hover {
        background-color: $hover-blue;
      }

      &:active {
        background-color: $active-blue;
      }

      &#dropdown-btn {
        font-size: 20px;
        min-width: fit-content;
        padding: 4px 8px 8px 8px;

        > .dropdown-tabs-popup {
          background-color: white;
          box-shadow: 0 0 5px rgba(192,192,192,0.75);
          border-radius: 5px;
          font-weight: normal;
          margin-left: -8px;
          margin-top: 16px;
          max-height: 0;
          overflow: hidden;
          padding-left: 0;
          position: absolute;
          transition: max-height .3s;
          width: auto;
          z-index: 1;

          &.expand {
            max-height: 70vh;
            overflow-y: auto;
            z-index: 1;
          }

          > ul {
            li {
              align-items: center;
              color: $blue;
              font-family: "Roboto", sans-serif;
              font-size: 12px;
              display: flex;
              padding: 12px 16px;

              &.active, &:hover {
                background-color: $dark-gray;
                cursor: pointer;
              }
            }
          }
        }
      }

      &#prev-btn, &#next-btn {
        font-size: 16px;
        font-weight: normal;
        min-width: fit-content;
        margin: 0 2px;
        padding: 10px 8px;
      }
    }
  }

  #tableauVizWrap {
    display: flex;
  }

  .non-executive-dashboard {
    @media screen and (min-width: 1920px) {
      justify-content: center;
    }
  }

  .disabled {
    background-color: $gray;
  }
}

.swal2-container > .swal2-popup.jumpToClaimsPopup {
  position: absolute;
  top: 200px;
  left: 50%;
  width: 400px;
  padding: 0;
  border-radius: 0;
  padding-bottom: 0 !important;
  box-shadow: 0 0 10px rgba(0,0,0,0.4);

  .jumpToClaimsContent {
    margin: 0;
    background-color: white;
    max-height: 70vh;
    font-size: 13px;
    color: black;

    .claimsHeader {
      background-color: #D9D9D9;
      font-weight: 600;
      padding:8px;
      position: relative;

      >i {
        color: $blue;
        float: right;
        position: absolute;
        top: 10px;
        right: 10px;
      }
    }

    .claimsWarning {
      color: $red;
      p {
        background-color: $dark-gray;
        margin: 4px 4px 0px 4px;
        padding: 4px;
        font-size: 12px;
      }
    }

    .claimLinksContain {
      display: flex;
      flex-wrap: wrap;
      padding: 2px;

      .claimsLink {
        padding:2px;
        flex: 50%;
        flex-grow: 0;

        >a {
          background-color: #f5f5f5;

          .pWrap {
            padding: 8px 4px 8px 18px;
            background-color: #f5f5f5;
            height: 45px;
            display: flex;
            align-items: center;

            &:hover {
              background-color: rgba(0,0,0,0.1);
            }

            >p {
              color: black;
              text-align: left;
              font-size: 12px;
              max-height: 30px;
              display: -webkit-box;
              -webkit-line-clamp: 2;
              -webkit-box-orient: vertical;
              overflow: hidden;
              text-overflow: ellipsis;
            }

            .fa-exclamation-circle {
              color: $red;
              margin-left: 8px;
            }
          }
        }
      }
    }
  }
}


.hidden {
  visibility: hidden;
}