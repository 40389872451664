/* start: swal */
.swal2-icon {
  margin-top: 32px !important;

  &.swal2-warning {
    border-color: $red !important;
    color: $red !important;
  }
}

.swal2-popup {
  background-color: $white !important;
  padding-bottom: 32px !important;
}

.swal2-success-circular-line-left, .swal2-success-fix, .swal2-success-circular-line-right {
  background-color: $white !important;
}

.swal2-html-container > .notification-container {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 24px;

  > span {
  font-size: 10px;
  }

  > input[type=checkbox] {
    accent-color: $blue;
  }
}
.swal2-actions {
  gap: 12px;

  > button {
    // width: 100px;

    &.swal2-confirm {
      color: $white !important;
      background-color: $blue !important;
      opacity: 1;

      &:focus {
        box-shadow: none !important;
      }

      &:hover:not([disabled]) {
        background-color: $hover-blue !important;
      }

      &:active:not([disabled]) {
        background-color: $active-blue !important;
      }

      &:disabled {
        cursor: not-allowed;
        background-color:  $disabled-gray !important;
      }
    }

    &.swal2-cancel {
      color: $blue !important;
      border: 1px solid $blue !important;
      background-color: transparent !important;
      background: none !important;

      &:focus {
        box-shadow: none !important;
      }

      &:hover:not([disabled]) {
        color: $hover-blue !important;
        border: 1px solid $hover-blue !important;
      }

      &:active:not([disabled]) {
        color: $active-blue !important;
        border: 1px solid $active-blue !important;
      }

      &:disabled {
        cursor: not-allowed;
        color: $disabled-gray !important;
        border: 1px solid  $disabled-gray !important;
      }
    }
  }

  > .swal2-styled {
    margin: 0 !important;
    // padding-left:0;
    // padding-right:0;
  }
}

// .swal2-success-ring {
//   border: 2px solid $blue !important;
// }

/* end: swal */

/* start: react-tabs */
.react-tabs__tab {
  padding: 0;
  width: 300px;
}
/* end: react-tabs */

/* start: react-toastify */
:root {
  --toastify-color-progress-light: $main !important;
}

.Toastify__toast {
  min-height: 0 !important;
}

.Toastify__toast-body {
  padding: 0 !important;
}

.custom-tooltip {
  z-index: 1 !important;
  background-color: $light-gray !important;
  color: $xevant-gray !important;
  padding: 8px 16px !important;
  border-radius: 5px !important;
  box-shadow: rgba(67, 71, 85, 0.27) 0px 0px 0.25em !important;
  opacity: 1 !important;

  &.white {
    background-color: $white !important;
  }
  &.claims-question-tool-tip {
    max-width: 400px !important;
    text-align: left !important;
    top: 0px !important;
  }
}

.custom-tooltip-arrow {
  background-color: $light-gray !important;
  color: $xevant-gray !important;
  box-shadow: rgba(67, 71, 85, 0.27)  -1px -1px 0 !important;

  &.right {
    box-shadow: rgba(67, 71, 85, 0.27)  -1px 1px 0 !important;
  }

  &.white {
    background-color: $white !important;

  }

  &.claims-question-tool-tip {
    transform: rotate(315deg);
    top: 12px !important;
  }
}

.styles-module_show__2NboJ {
  &.multiline {
    max-width: 250px !important;
  }
}

div.reactour__popover {
  transition: transform 0.3s ease 0.05s !important;
}

div.reactour__popover > div > div > button:first-child {
  display: none !important;
}

input[data-com-onepassword-filled='light'] {
  border-radius: 5px;
  background-color: rgb(219, 237, 255) !important;
}