.form.new-client-form {
  width: 75%;

  .form-left {
    flex: 2;

    .form-input {
      margin: 30px 0;
    }
    .enable-email {
      color: $xevant-gray;
      display: flex;
      width: 170px;
      height: 24px;
      align-items: center;
      margin-left: 30px;
      font-size: 14px;
    }

    .demo-toggle > .form-input {
      display: flex;
      flex-direction: row-reverse;
      justify-content: flex-end;
      align-items: flex-start;
      gap: 16px;
      margin-left: 30px;

      > .input-label {
        color: $xevant-gray;
        font-weight: 400;
        margin-bottom: 0;
        align-self: center;
      }

      > .input {
        width: auto;
      }
    }

    .add-user-input {
      display: flex;
      gap: 10px;

      .text-and-dropdown {
        flex: 1;
        background-color: white;
        border: 1px solid $dark-blue;
        border-radius: 5px;
        height: 35px;
        display: flex;

        .add-user-text-input {
          flex: 2;
        }
        .mid-border {
          background-color: $xevant-gray;
          width: 1px;
          height: 16px;
          align-self: center;
        }

        > .form-input {
          margin: 0 !important;

          > .input {
            border: none;
            height: 32px;
          }
        }

        input, select {
          background: transparent;
          border: none;
        }
        input {
          padding: 4px 10px 4px 20px;
          flex: 1;
        }
        select {
          align-self: center;
          font-size: 14px;
          color: $xevant-gray;
          padding: 4px 6px;
          margin-right: 5px;
          cursor: pointer;
        }
      }

      .add-user-button {
        background-color: transparent;
        border: 1px solid $dark-blue;
        border-radius: 5px;
        color: $dark-blue;
        font-size: 14px;
        font-weight: 600;
        width: 100px;
        padding: 0;
      }
    }

    .added-users {
      display: flex;
      gap: 15px;
      flex-wrap: wrap;
      margin-top: 10px;

      .added-user-box {
        background-color: $dark-gray;
        color: $dark-blue;
        padding: 5px 0 5px 10px;
        font-size: 12px;
        border-radius: 2px;

        .remove-user-x {
          cursor: pointer;
          font-weight: 600;
          padding: 5px 10px;
        }
      }
    }
  }

  .form-right {
    .module-section {
      width: fit-content;

      .list-view {
        padding: 0 12px;

        .item {
          padding: 0;

          > div {
            flex: none;
            align-self: center;

            &.item-field {
              flex-direction: unset;
              justify-content: start;
              margin-left: 18px;
              color: $xevant-gray;
            }
          }
        }
      }

      &.has-errors {
        border: 1px solid $red;
      }
    }
  }

  .input.toggle > .toggle-input {
    width: 34px;
    height: 20px;

    &.active {
      background-color: $blue;

      > .toggler-inner {
        .toggler {
          background-color: $white;
        }
      }
    }
    > .toggler-inner {
      .toggler {
        background-color: $xevant-gray;
      }
    }
  }
}

.form.new-user-group-form {
  padding-top: 1px;

  .form-input {
    margin: 30px 0;
  }
}

.form.user-form {
  > .enable-datalogic {
    color: $xevant-gray;
    display: flex;
    width: 175px;
    height: 24px;
    align-items: center;
    margin: 30px 0 30px 30px;
    font-size: 14px;

    > .form-input {
      flex: 0;
      margin-right: 15px;
    }
  }

  .input.toggle > .toggle-input {
    width: 34px;
    height: 20px;

    &.active {
      background-color: $blue;

      >.toggler-inner {
        .toggler {
          background-color: $white;
        }
      }
    }

    > .toggler-inner {
      .toggler {
        background-color: #737373;
      }
    }
  }
}