.menu-container {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 264px;

  .sidemenu-container {
    background-color: $gray-5;
    position: relative;
    overflow-y: scroll;
    height: 100%;

    .img-container {
      padding: 24px 0 24px 16px;

      &.centered {
        padding: 24px 0;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }

    .menu-container {
      margin-bottom: 50px;
      margin-top: 10px;
    }

    .menu-list-container {
      .menu-item-container {
        height: 36px;

        .parent-menu-item {
          display: flex;
          align-items: center;
          justify-content: start;
          cursor: pointer;

          i {
            color: $gray-70;
          }

          &.active {
            background-color: $blue-1;
            border-left: solid 4px $blue-50;
            font-weight: 600;

            > .menu-value {
              color: $blue-50;
            }

            > .icon-container {
              margin-left: 12px;

              > i {
                color: $blue-50;
              }
            }
          }

          .icon-container {
            margin: 8px 12px 8px 16px;
            font-size: 15px;
            height: 20px;
            width: 20px;
            display: flex;
            align-items: center;
            justify-content: center;
          }

          .menu-value {
            font-size: 14px;
            font-weight: 400px;
            color: $gray-90;
          }
        }
      }
    }

    .search-container {
      > .menu-search {
        background: $gray-5;
        padding: 0 16px;
        height: 36px;

        > div {
          font-family: 'Montserrat';
          font-size: 14px;

          > input {
            border-radius: 5px;
            border-style: solid;
            border-width: 1px;
            padding: 0 34px;
            width: 100%;
            border-color: rgba(0, 0, 0, 0.63);
            font-size: 14px;
          }

          &.dropdown-menu {
            background-color: $white;
            border: 1px solid $gray;
            border-radius: 0 0 5px 5px;
            display: flex !important;
            flex-direction: column;
            inset: 28px auto auto 0px !important;
            margin-top: 10px;
            overflow-y: scroll;
            transform: none !important;
            z-index: 1;
            margin-left: 16px;

            > .dropdown-item {
              overflow: unset;
              padding: 5px 10px;
              display: flex;
              align-items: center;
              font-family: 'Roboto', sans-serif;

                > i {
                  padding-right: 5px;
                  color: $icon-gray
                }
                > div {
                  padding-right: 5px;
                  color: black;
                }
                > div.tag {
                  background-color: $blue;
                  color: white;
                  border-radius: 5px;
                  display: flex;
                  flex-direction: row;
                  justify-content: center;
                  align-items: center;
                  padding: 2px 8px;
                  gap: 10px;
                  font-size: 12px;
                  margin-left: auto;
                }

                > div.show-list {
                  text-decoration: underline;
                  padding: 10px 0 10px 25px;
                  width: 100%;
                  color: $blue;
                  border-top: 1px solid $dark-gray;
                }
              &:hover {
                background-color: $gray;
              }
              &.link-all {
                padding: 0;
              }
            }
          }
        }
        > i {
          position: absolute;
          left: 24px;
          top: 10px;
          z-index: 1;
          font-size: 16px;
          color: $gray-70;
        }
      }
    }

    .menu-footer {
      margin-top: 40px;
    }




  }

  > .menu-xevant-logo {
    position: sticky;
    bottom: 0;
    z-index: 1;
    padding: 19.2px 0 25.6px 12.8px;
    background-color: $gray-5;

    &.shadow {
      box-shadow: rgba(0,0,0,0.04) 0px -4px 4px 0px ;
    }

    img {
      height: 12px;
    }
  }
}