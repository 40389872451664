.form.datalogic-upload-form {
  width: 375px;
  margin-bottom: 50px;

  .button {
    &.transparent {
      background-color: transparent;
      color: $dark-blue;

      &:hover:not([disabled]) {
        color: $hover-blue;
        border: 1px solid $hover-blue;
      }

      &:active:not([disabled]) {
        color: $active-blue;
        border: 1px solid $active-blue;
      }
    }

    &.center {
      margin: 0 auto;
    }

    &:disabled {
      color: $disabled-gray;
      cursor: no-drop;
    }
  }

  .break-line {
    margin: 30px 0;
    border-top: 1px solid $dark-blue;
  }

  .form-input > .input.toggle > .toggle-input {
    width: 34px;
    height: 20px;

    .toggler-inner > div.toggler {
      background-color: #737373;
    }

    &.active > .toggler-inner > .toggler {
      background-color: white;
    }
  }

  .form-input {
    margin: 20px 0;
  }

  .claim-file-upload {
    cursor: pointer;

    .custom-file-input {
      padding: 30px;
      color: $text-gray;
      font-size: 14px;
      text-align: center;
      gap: 40px;

      .custom-file-input-right {
        font-weight: 600;
        > p {
          font-size: 11px;
          font-weight: 400;
        }
      }

      > input {
        display: none;
      }
    }
  }

  .toggle-section {
    flex-direction: row;
    flex-wrap: wrap;
    gap: 16px;
    padding: 24px 0 0 0;

    > h1 {
      align-self: flex-start;
      background-color: $white;
      border-radius: 5px;
      border: 1px solid $dark-blue;
      color: $dark-blue;
      font-size: 16px;
      font-weight: 400;
      margin: -43px 0 0 32px;
      padding: 8px 16px;
    }

    .form-group {
      flex: 1;
      gap: 24px;
      justify-content: center;
      margin-bottom: 0;
      margin-top: -24px;

      > .form-input {
        display: flex;
        justify-content: center;
        gap: 8px;
        margin: 24px 0;

        > input {
          accent-color: $blue;
          margin: 5px;
        }

        > label {
          align-self: center;
          color: $text-gray;
          font-size: 14px;
          font-weight: 400;
        }
      }
    }
  }

  > button {
    > i {
      margin-right: 10px
    }
    border: 1px solid $dark;
  }

  .submit-claim {
    > button {
      width: 100%;

      &:hover:not([disabled]) {
        background-color: $hover-blue;
      }

      &:active:not([disabled]) {
        background-color: $active-blue;
      }

      > i {
        margin-right: 10px
      }
    }
  }

  .input.draganddrop.file-upload > .drag-drop-file-input-right > .filename-wrap > span.red-x {
    z-index: 0;
  }
}

.file-success {
  display: flex;
  gap: 16px;
  p{
    color: $dark-blue;
  }
  .xmark-icon{
    font-size: 32px;
    color: $dark-blue;
  }
  .success-circle{
    color: $green;
  }
}

.file-upload-success-margin {
  margin-top: 64px
}

.file-claim-success-margin {
  margin: 64px 0px
}

.file-name-container {
  display: flex;
  gap: 32px;
  align-items: center;
}


.file-loading-text {
  display: flex;
  justify-content: space-between;
  width: 100%;
  position: relative;
}

.dl-error-summary {
  .error-summary-button {
    margin-bottom: -35px;
  }

  .max-width {
    max-width: 300px;
  }
}