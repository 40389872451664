.form.bl-form {
  width: 100%;
  margin-top: 80px;

  .bidlogic-content {
    margin-top: 8px;
    font-size: 14px;

    h2 {
      font-size: 20px;
      font-weight: 600;
      margin: 0;
    }

    .align-right {
      margin-top: 0;
      text-align: right;

      i {
        font-size: 24px;
        color: $red;
        cursor: pointer;
        margin-left: 10px;
      }
    }

    .group-wrap {
      box-shadow: 0 2px 4px rgba(0,0,0,0.25);
      border-radius: 10px;
      background-color: #f9f9f9;
      margin-bottom: 24px;
      margin-top: 24px;

      .group-header {
        background-color: $blue;
        border-radius: 10px 10px 0 0;
        height: 40px;
        color: white;
        display: flex;
        align-items: center;

        > div {
          flex: 1;
          text-align: center;
        }
      }

      .group-content-table {
        color: #737373;

        .row {
          display: flex;
          height: 40px;
          align-items: center;
          border-bottom: 1px solid $blue;

          &:last-child {
            border-bottom: none;
          }

          .col {
            flex: 1;
            text-align: center;

            &.action-buttons {
              display: flex;
              justify-content: center;
              font-size: 16px;
              margin: -5px 0;
              align-items: center;

              .action-button {
                color: $hover-blue;
                display: flex;
                flex-direction: column;
                justify-content: center;
                width: 40px;
                height: 35px;
                cursor: pointer;

                &.delete-button {
                  color: $red;
                  cursor: pointer;
                }

                &:hover {
                  opacity: .85;
                }

                >.loader-circle-lines {
                  margin: auto;
                }

                > .warning {
                  color: $orange;
                }
              }
            }
          }
        }
      }

      &.group-content, .group-content {
        padding: 24px;
        gap: 24px;

        &.collapsible {
          display: flex;
        }

        .button-wrap {
          align-self: center;
          display: flex;
          flex-direction: column;
          gap: 32px;

          i {
            font-size: 24px;
            color: $blue;
            cursor: pointer;

            &.red {
              color: $red;
            }
          }
        }

        .group-right {
          flex: 1;

          &.custom-reprice-group-rule {
            margin-bottom: 10px;

            &:last-child {
              margin-bottom: 0;
            }

            .select {
              height: 40px;
            }
          }
        }

        .horizontal-rule {
          margin: 48px 0;
        }

        .form-group {
          flex: 1;
          margin-bottom: 24px;

          &:last-child {
            margin-bottom: 0;
          }

          .invisible-input {
            visibility: hidden;
          }

          i {
            font-size: 24px;
            color: $blue;

            &.red {
              color: $red;
            }

            &.custom-delete-button {
              align-self: center;
              cursor: pointer;

              &.first-child {
                padding-top: 1em;
              }
            }
          }
        }

        &.collapsed {

          .button-wrap {
            flex-direction: row;
            gap: 24px;
          }
        }
      }

      .half-width {
        max-width: 50%;
      }

      .add-pbm-button {
        width: 100%;
        background-color: white;
        color: $blue;
        border-radius: 10px;
        height: 70px;

        i {
          font-size: 21px;
          margin-right: 10px;
        }

        &:disabled {
          background-color: $disabled-gray;
          cursor: not-allowed;
        }

        &:hover:not([disabled]) {
          color: $hover-blue;
        }
      }

      .add-custom-reprice-group-button {
        width: 100%;
        background-color: white;
        color: $blue;
        border-radius: 10px;
        height: 35px;

        i {
          font-size: 21px;
          margin-right: 10px;
        }

        &:disabled {
          background-color: $disabled-gray;
          cursor: not-allowed;
        }

        &:hover:not([disabled]) {
          color: $hover-blue;
        }
      }

      .add-rule-button {
        min-width: 125px;
        background-color: white;
        color: $blue;
        border-radius: 10px;
        height: 35px;
        box-shadow: 0 2px 4px rgba(0,0,0,0.25);

        i {
          font-size: 21px;
          margin-right: 10px;
        }

        &:disabled {
          background-color: $disabled-gray;
          cursor: not-allowed;
        }

        &:hover:not([disabled]) {
          color: $hover-blue;
        }
      }

      .custom-reprice-group--header {
        justify-content: space-between;
      }

      .run-button-wrap {
        flex: 1;
        align-self: end;

        .run-button {
          background-color: $blue;
          height: 37px;
          width: 100%;

          &:hover {
            background-color: $hover-blue;
          }
        }
      }
    }
  }
}

.additional-input-group {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  justify-content: space-evenly;

  .additional-input.bl-input {
    border: 1px solid black;

    h2 {
      margin: 0;
      padding: 6px;
      font-family: Arial, Helvetica, sans-serif;
      font-size: 20px;
      font-weight: 500;

      &.blue-bg {
        background-color: $bl-blue;
        color: white;
      }

      &.green-bg {
        background-color: $xevant-green;
        color: white;
        text-align: center;
      }
    }

    .form-group {
      margin-bottom: 0;
      gap: 0;
    }

    .apply-button {
      margin: 6px;
      height: 37px;
      background-color: $bl-blue;

      &:disabled {
        background-color: $disabled-gray;
        cursor: default;
      }
    }

    table, th, td {
      border: none;
      border-collapse: collapse;
    }

    .table-wrap {
      font-size: 12px;

      .table-header {
        background-color: $bl-blue;
        color: white;
        padding: 12px;
        text-align: center;
        width: 100%;
        font-weight: bold;
        align-items: center;
        height: 60px;

        th {
          padding: 0 6px;
        }
      }

      .table-row {
        display: flex;
      }

      .current-rates-table-rows {
        text-align: center;

        .table-row {
          display: block;
          border-bottom: 1px solid $disabled-gray;
        }

        .col {
          display: inline-block;

          &:first-child {
            width: 25%;
          }
        }
      }

    }


    &.rate-option-select {
      width: 100%;
      font-size: 12px;

      .rate-options-wrap {
        height: 150px;
        overflow: auto;

        .option-wrap {
          height: 60px;
          border-bottom: 1px solid $disabled-gray;
          display: flex;
          align-items: center;

          .option-input {
            display: none;
          }

          .option-input:checked + .option-label {
            background-color: #91bbdd;
          }

          .option-label {
            width: 100%;
            height: 100%;
            padding: 22px 12px 0 12px;

            &:hover {
              background-color: $disabled-gray;
              cursor: pointer;
            }
          }
        }
      }
    }

    &.current-rates-table {
      flex: 2;
      min-height: 600px;

      .table-wrap {
        display: flex;
      }

      .table-right {
        border-left: 1px solid $disabled-gray;
        flex-grow: 3;
        display: flex;

        > table {
          flex-grow: 3;
        }
      }

      .table-left, .table-right {
        tr {
          min-height: 30px;
          height: 35px;
          padding: 6px 12px;

          td {
            height: 35px;
            padding: 6px 12px;
            white-space: nowrap;

            &:not([rowspan]) {
              padding: 6px 18px;
            }
          }

          &:nth-child(even) {
            background-color: $light-gray;
            border-bottom: 1px solid $disabled-gray;
          }
        }
      }
    }

    &.dynamic-rates-table {
      flex: 1;
      min-height: 400px;

      .table-wrap {
        flex-grow: 3;
        display: flex;
      }

      .response-rates-table {
        min-height: 580px;
        flex-grow: 3;

        thead > tr {
          min-height: 30px;
          height: 35px;
        }

        tbody > tr {
          height: 35px;

          &:nth-child(even) {
            background-color: $light-gray;
            border-bottom: 1px solid $disabled-gray;
          }
        }

        .rate-input {
          padding: 0 6px;

          .input {
            width: 70px;
            border-radius: 2px;
            border: 1px solid $icon-gray;
            margin-right: 10px;

            input {
              font-size: 12px;
              padding: 2px 0 2px 4px;
              border: none;
              height: unset;
              width: 75px;
            }
          }

          i {
            margin-left: 4px;
            color: black;
          }

          &.discount {
            input {
              width: 85px;
            }

            i {
              margin-right: 6px;
              margin-left: -30px;
            }
          }
        }
      }

      .apply-button {
        float: right;
      }
    }

    &.ratio-cost-awp {
      h2 {
        padding: 6px 48px;
      }

      .ratio-input {
        margin: 6px;
        border: 1px solid $icon-gray;
      }
    }
  }
}

.modal.reprice-details-modal {
  border-radius: 8px;
  box-shadow: 0px 2px 8px 0px #0000001F;
  max-height: 60%;
  width: 50%;
  overflow-y: auto !important;
  padding: 0 24px;

  > .modal-close {
    color: #808080;
    font-size: 24px;
  }

  > .reprice-details {
    font-size: 12px;

    > h2 {
      color: $blue;
      font-size: 24px;
      font-weight: 700;
    }

    > hr {
      margin-bottom: 12px;
    }

    > .reprice-details--group {
      display: flex;

      &.last {
        margin-bottom: 12px;
      }

      > .reprice-details--item {
        width: 50%;

        > .reprice-details--label {
          color: $blue;
          font-weight: 600;
        }

        > .reprice-details--body {
          margin: 8px 0 12px 0;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          max-width: 100%;
        }
      }
    }

  }

}
