.create-password {
  flex: 1;
  background-color: $white;
  padding: 40px 40px 40px 40px;
  border-radius: 3px;

  .instruction {
    margin-bottom: 40px;
    font-size: 14px;
  }
}

.create-password-container {
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding: 0px 80px 70px 80px;
  > h2 {
    margin: 0 0 16px 0;
    > span {
      color: $dark;
      font-weight: 400;
    }
  }

  > p {
    font-size: 11px;

    > span {
      color: $blue;
    }
  }
}

.left-content {
  .left-content-icon {
    margin: auto;
    text-align: center;
    > i {
      color: $red;
      font-size: 250px;
    }
  }
}

.right-form {
  .invalid {
    height: 100%;
    display: flex;
    flex-direction: column;
    vertical-align: middle;

    > h1 {
      color: $red !important;
      margin-top: auto;
    }
    > p:last-child {
      margin-bottom: auto;
    }
  }
}
