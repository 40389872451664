#finance-summary-approval {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  padding: 10px;
  border: solid 1px $black;
  border-radius: 5px;
  background-color: $white;
  display: flex;
  gap: 10px;
  flex-direction: column;

    .timestamp {
      margin-top: 8px;
    }
    .schedule-input {
      display: flex;
      gap: 5px;

      .button-spacer {
        display: flex;
        align-items: end;

        button {
          height: 37px  ;
        }
      }
    }
}
