* {
  box-sizing: border-box;
}

body {
  margin: 0px;
  padding: 0px;
  font-family: 'Open Sans', sans-serif;
  font-family: 'Montserrat', sans-serif;
  font-weight: 400;
  font-size: 16px;
}

a {
  text-decoration: none;
  color: $dark-blue;
  cursor: pointer;
}

button {
  font-family: inherit;
}

ul {
  margin: 0px;
  padding: 0px;
  list-style: none;
}

p {
  margin: 0px;
}

input,
textarea,
select {
  font-family: inherit;

  &:focus {
    outline: none;
  }
}

#app {
  display: flex;
  align-items: stretch;
  position: absolute;
  width: 100%;
  height: 100%;
}

.avatar-column {
  .avatar {
    width: 50px;

    > img {
      height: 50px;
    }
  }
}

.spaced-bottom {
  margin-bottom: 40px;
}

.upload-progress {
  position: absolute;
  width: 100%;
  height: 5px;
  padding: 0px 12px;
  border-radius: 3px;
  bottom: -5px;

  > .progress {
    background-color: $green;
    height: 5px;
    border-radius: 20px;
  }
}

// Adjusted styling to accommodate fixed .page-header

.page-header + * {
  margin-top: 60px;
}

.page-header + .course-upload-form,
.page-header + .new-client-form,
.page-header + .questionnaire-form,
.page-header + .user-form {
  margin-top: 80px;
}

.page-header + .settings-form {
  margin-top: 60px;
}