.action-bar {
  display: flex;
  min-height: 40px;
  padding: 0px;
  // margin-bottom: 20px;
  margin-bottom: 20px;

  &.background-white {
    background-color: $white;
  }

  &.padded {
    height: auto;
    padding: 20px;
  }

  &.padded-top-bottom {
    padding: 20px 0px;
  }

  .action-search {
    flex: 1;
    display: flex;
    align-items: center;
    background-color: $white;
    height: 40px;
    margin-right: 30px;
    padding: 0px 20px;
    border-radius: 20px;

    &:last-child {
      margin-right: 0px;
    }

    > i {
      margin-right: 8px;
      color: #0a91b7;
    }

    > input {
      flex: 1;
      display: flex;
      align-items: center;
      background-color: transparent;
      height: 40px;
      border: none;
      font-size: 14px;
      font-family: inherit;
    }
  }

  .action-buttons {
    &.flex {
      flex: 1;
      display: flex;
      align-items: center;
    }

    &.space-between {
      justify-content: space-between;
    }

    &.right {
      justify-content: flex-end;
    }

    &.back-button {
      display: flex;
      align-items: center;
    }

    > a,
    > button {
      margin-right: 10px;

      &:last-child {
        margin-right: 0px;
      }
    }
  }
}