.audit-logs-date-range-selection {
  display: flex;
  gap: 12px;
  margin-bottom: -35px;
  justify-content: end;
  margin-right: 442px;

  > .form-input {
    flex: none;

    > .input {
      height: auto;
      width: 160px;

      > i {
        position: absolute;
      }
      > input {
        z-index: 1;
        background: none;
      }
    }
  }
}