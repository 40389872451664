.iconify-centered {
    display: flex;
    align-items: center;
    justify-content: center;
}

.icon-tooltip {
  cursor: pointer;
  margin-left: 5px;
}
