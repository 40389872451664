.dashboard-filter-container {
  margin: 24px auto 24px auto;
  display: flex;
  max-width: 1300px;
  border-radius: 5px;
  box-shadow: 0px 2px 2px 1px rgba(10, 25, 39, 0.06);
  background-color: $gray;
  padding: 12px 16px;
  gap: 24px;
  flex-direction: column;

  > .dashboard-filter-header {
    display: flex;
    justify-content: space-between;
    width: 100%;

    > .dashboard-filter-additional-container {
      display: flex;
      align-items: center;
      gap: 8px;

        > p {
          font-size: 16px;
        }

        > .dashboard-filter-caret {
          cursor: pointer;
          display: flex;
        }

        > .dashboard-filter-question-icon {
          font-weight: 200;
          font-size: 14px;
        }
    }

    > .dashboard-filter-input-container {
      display: flex;
      gap: 16px;

      > .form-input {
        width: 300px;

        > .input {
          border-radius: 0px;
        }

        > .input.multiselect {
          height: 35px;

          > .multiselect-input > .multiselect-values > ul  {
            height: 35px;

            > li {
              height: 35px;
              font-size: 12px;

              .text {
                color: $black;
                background-color: transparent;
              }
            }
          }

          .multiselect-input > .multiselect-options {
            top: 36px;
          }

          .multiselect-input > .multiselect-options.collapsed {
            max-height: 390px;
          }
        }
      }

       .button {
        border-radius: 0px;
      }
    }
  }

  > .dashboard-filter-content {
    display: none;

    > .dashboard-filter-grid {
      display: grid;
      grid-column-gap: 12px;
      grid-row-gap: 24px;
      grid-template-columns: repeat(6, 1fr);

       .dashboard-filter-grid-item {
        display: flex;
        gap: 8px;
        position: relative;

        > .delete-icon-container {
          display: flex;
          height: 100%;
          margin-top: 12px;
          color: $red;
          .iconify-centered {
            cursor: pointer;
          }
        }

        > .custom-tooltip {
          top: 60px !important;
        }

        .form-input {

          > .input-label {
            font-size: 12px;
          }

          > .input {
            border-radius: 0px;
          }

          > .input.multiselect {
            height: 26px;
            font-size: 12px;
            padding: 0 10px;

            .search-input {
              font-size: 12px;
              white-space: nowrap;
              width: 100%;
              overflow: hidden;
              text-overflow: ellipsis;
            }

            > .multiselect-input > .multiselect-values {
              flex-wrap: nowrap;
              font-size: 12px;
            }

            .multiselect-input > .multiselect-options > ul > li > .text {
              font-size: 12px;
            }

            > .multiselect-input > .multiselect-values > ul  {
              height: 26px;

              > li {
                height: 26px;
                font-size: 10px;

                .text{
                  color: $black;
                  padding: 0;
                  background-color: transparent;
                  font-size: 12px;
                }
              }
            }

            > .multiselect-input > .multiselect-values > #text {
              white-space: nowrap;
              width: 100%;
              overflow: hidden;
              text-overflow: ellipsis;
            }

            .multiselect-input > .multiselect-options {
              top:28px;
            }
          }
        }

        .clear-icon-container {
          position: absolute;
          right: 34px;
          top: 31px;
          font-size: 10px;
          cursor: pointer;
          color: $red;
        }
      }
    }

     .dashboard-filter-add-button{
      width: 88%;
      height: 26px;
      border-radius: 0px;
      border-style: dotted;
      color: $xevant-gray;
      display: flex;
      gap: 8px;
      justify-content: center;
      align-items: center;
      cursor: pointer;
    }
  }

   .dashboard-add-filter-container {
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
  }
}

.dashboard-collapse-height {
  height: fit-content;
  overflow: visible;
}

.dashboard-hide-content {
  display: contents !important;
}