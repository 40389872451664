p.popout-menu-loading{
  margin: 30px 20px;
  font-size: 14px;

  &:after{
    overflow:hidden;
    display: inline-block;
    vertical-align: bottom;
    -webkit-animation: ellipsis steps(4,end) 900ms infinite;
    animation: ellipsis steps(4,end) 900ms infinite;
    content: "\2026"; /* ascii code for the ellipsis character */
    width: 0px;
  }
  @keyframes ellipsis {
    to {
      width: 1.25em;
    }
  }
  @-webkit-keyframes ellipsis {
    to {
      width: 1.25em;
    }
  }
}
.popout-menu-nav {
  padding-top: 12px;
  .placeholder{
    // margin: 10px 0;

    .placeholder-name-container{
      height: 36px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding-right: 16px;

      &:hover {
        cursor:pointer;
      }

      .icon-container {
        margin: 8px 12px 8px 16px;
        font-size: 15px;
        height: 20px;
        width: 20px;
        display: flex;
        align-items: center;
        justify-content: center;

        i {
          color: $gray-70;
        }
      }

      .placeholder-name {
        font-weight: 400;
        display: inline-block;
        width: 90%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        flex: 1;
        color: $gray-90;
        font-size: 14px;
      }

      .placeholder-arrow {
        font-size: 10px;
        margin-left: auto;

        > i {
          transition: all .2s;
          color: $gray-70;

          &.rotate {
            // transform: rotate(180deg) translateY(-4px);
            transform: rotate(180deg);
          }
        }
      }
    }

    .placeholder-children {
      height: 0;
      overflow: hidden;
      transition: all .2s;
      width: 100%;

      &.expand{
        height: auto;
        margin-bottom: 24px;

        > .placeholder > .placeholder-children.expand {
          background-color: #F5F5F5;
          margin: 0;
          padding-bottom: 24px;
          a > .link {
            padding: 8px 0 8px 56px;
            color: #424242;

            &.active {
              padding: 8px 0 8px 52px;
              color: $blue-50;
            }

          }
        }
      }
    }
  }

  .link {
    padding: 8px 0 8px 48px;
    font-size: 14px;
    color: $gray-90;

    &.active {
      background-color: $blue-1;
      border-left: solid 4px $blue-50;
      font-weight: 600;
      padding-left: 44px;
      color: $blue-50;
    }

    > p {
      overflow: hidden;
    }

    &:hover{
      cursor: pointer;
    }
  }
}