#bookmark-container {
    position: relative;
    > #bookmark {
      align-self: center;
      background-color: $dark-gray;
      display: flex;
      height: 25px;

      > .bookmark-icon {
        &:hover {
          background-color: #e1e1e1;
          cursor: pointer;
        }

        &.bookmark-active {
          &:hover {
            cursor: default;
            background-color: inherit;
          }

          &:active {
            color: $active-blue;
          }

          > div:first-of-type {
            svg {
              color: $xevant-green;
              font-size: 14px;
              margin: 5px 9px;
            }
          }
        }

        > div:first-of-type {
          justify-content: unset !important;

          svg {
            color: $blue;
            margin: 4px 8px;
          }
        }

        &.disabled {
          cursor: default;
          &:hover {
            background-color: initial;
          }
        }

        >.bookmark-loader-wrap {
          padding: 4.5px 9px;
        }
      }

      > .bookmark-list {
        color: $blue;

        &:hover {
          background-color: #e1e1e1;
          cursor: pointer;
        }

        align-items: center;
        border-left: 1px solid $blue;
        display: flex;
        font-size: 12px;

        > span {
          margin-left: 8px;
        }

        > i {
          margin: 0 8px 0 16px;
        }
      }
    }

    .bookmark-popup {
      background-color: white;
      box-shadow: 0 0 5px rgba(192,192,192,0.75);
      color: $xevant-gray;
      margin-top: 20px;
      max-height: 0;
      overflow: hidden;
      position: absolute;
      right: 0px;
      transition: max-height .3s;
      width: max-content;
      z-index: 1;

      &.expand {
        max-height: 400px;
        overflow: auto;
        z-index: 1;
      }

      > ul {
        padding: 12px;
        font-size: 12px;

        div.custom-tooltip {
          position: fixed !important;
        }

        li {
          align-items: center;
          display: flex;
          margin-bottom: 4px;

          &:last-child {
            margin-bottom: 0;
          }

          > .bookmark-icon {
            background-color: $dark-gray;
            border-radius: 5px 0 0 5px;
            border-right: 1px solid $blue;

            &:hover {
              background-color: $light-gray;
              cursor: pointer;
            }

            > div:first-of-type {
              justify-content: unset !important;
              margin: 8px;

              svg {
                color: $red;
                font-size: 16px;
              }
            }
          }

          > a {
            background-color: $dark-gray;
            border-radius: 0 5px 5px 0;
            line-height: 30px;
            padding: 1px 8px;
            width: 100%;

            &:hover {
              background-color: $light-gray;
              cursor: pointer;
            }

            > i, .iconify {
              color: $blue;
              vertical-align: middle;
              margin-right: 12px;
              width: 22px;
              font-size: 22px;
            }

            > span {
              vertical-align: middle;
              margin-left: 10px;
            }
          }
        }
      }
    }
  }

.bookmark-toast {
  color: $blue;
  font-family: 'Montserrat', sans-serif;
  font-size: 12px;
  font-weight: 400;
  padding: 16px;
  top: 70px;
  width: fit-content;
}

.add {
  svg {
    color: $green !important;
  }
}

.remove {
  svg {
    color: $red;
  }
}