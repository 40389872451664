.resources > .content > .middle.release-notes-mid {
  padding: 0;

  p {
    margin: 12px 0;
  }

  .left-info {
    background-color: $white;
    width: 400px;

    .login-logo-left {
      height: 70px;
      margin: 10px;
    }

    .left-content {
      padding: 15px 40px;

      h5 {
        color: $blue;
        cursor: pointer;
        font-size: 14px;
        font-weight: 600;
        margin: 0;

        &.topMargin {
          margin-top: 15px;
        }
      }

      a {
        display: block;
        font-size: 12px;
        margin: 7px 0;
        &:hover {
         color: $hover-blue;
        }

        &.active {
          font-weight: 600;
          color: $active-blue;
        }
      }
    }
  }

  .right-form {
    background-color: $white;
    padding: 32px 16px 16px 26px;
    width: 600px;

    h1 {
      margin-top: 0;
    }

    .doc-box {
      color: $xevant-gray;
      font-size: 12px;
      height: 100%;
      padding-bottom: 80px;
      padding-right: 10px;
      position: relative;
      text-align: justify;
      width: 100%;

      > .release-note--title {
        color: $blue;
        font-size: 24px;
        font-weight: 700;
        margin-bottom: 16px;
      }

      > .release-note--date {
        background-color: $blue;
        color: $white;
        margin-bottom: 16px;
        padding: 0 16px;
        width: fit-content;
      }

      > .release-note--body {
        display: block;
        height: inherit;
        overflow-wrap: break-word;
        overflow-x: scroll;
      }
    }

    .form-group {
      margin-bottom: 0;
      justify-content: center;

      label {
        color: $xevant-gray;
        font-size: 10px;
        line-height: 20px;
      }

      input {
        cursor: pointer;
      }
    }
  }
}