.subscription-form-modal {
  max-height: 95vh;
  overflow-y: scroll;

  margin: 10px 0;
  padding: 20px 30px;
  mask-image: linear-gradient(to bottom, transparent 0%, black 20px, black calc(100% - 20px), transparent 100%);
  -webkit-mask-image: linear-gradient(to bottom, transparent 0%, black 20px, black calc(100% - 20px), transparent 100%);

  form {
    width: 100%;
  }

  h2 {
    margin-top: 0;
    font-size: 18px;
    font-weight: 600;
    color: $main;
  }

  .form-group.radio-group {
    gap: 0;
    font-size: 12px;

    input[type='radio'] {
      margin: 0 10px 0 25px;
      &::before {
        width: 10px;
        height: 10px;
        border-radius: 10px;
        position: relative;
        background-color: $white;
        content: '';
        display: inline-block;
        border: 2px solid white;
      }

      &:checked::before {
        width: 10px;
        height: 10px;
        border-radius: 10px;
        position: relative;
        background-color: $main;
        content: '';
        display: inline-block;
        border: 2px solid white;
      }
    }
  }

  .form-group.schedule-group {
    gap: 12px;
    flex-wrap: wrap;

    .form-input {
      width: 160px;
      flex: none;
    }

    .form-group {
      gap: 12px;

      .form-input {
        width: 200px;
      }

      .input {
        padding: 0 10px 0 2px;
      }
    }

    .no-end-date-check-wrap {
      align-self: flex-end;
      display: flex;
      font-size: 14px;
      margin-bottom: 10px;
      align-items: center;

      .checkbox:hover {
        background-color: $dark-gray;
        border-radius: 50%;
      }

      input {
        margin: 5px;
        accent-color: $blue;
      }
    }
  }

  .dynamic-schedule-text {
    font-size: 12px;
    font-style: italic;
    margin-top: -10px;
    margin-bottom: 20px;
  }

  .form-break-border {
    width: 100%;
    border-top: 1px solid $text-blue;
    padding: 10px 0;
  }

  div.textarea.member-textarea > textarea {
    height: 60px;
  }

  div.textarea.member-textarea.with-fake {
    background-color: transparent;

    textarea {
      color: transparent;
      caret-color: black;
      line-height: 27px;

      ::placeholder {
        color: black;
      }
    }
  }

  div.fake-textarea-wrap {
    position: absolute;
    width: 100%;

    .input-label {
      color: transparent;
    }

    div.textarea > .member-fake-textarea {
      height: 60px;
      padding: 12px;
      flex: 1;
      font-size: 14px;
      outline: none;
      overflow: auto;
      line-height: 27px;
      word-break: break-word;

      .valid-email {
        padding: 4px 0;
        background-color: #F1F1F8;
      }
    }
  }

  div.is-skipped-if-empty-check-wrap {
    display: flex;
    font-size: 14px;
    align-items: center;

    .checkbox:hover {
      background-color: $dark-gray;
      border-radius: 50%;
    }

    input {
      margin: 5px;
      accent-color: $blue;
    }
  }

  .form-submit {
    margin-top: 40px;
    gap: 12px;
    justify-content: space-between;
    align-items: center;
    height: fit-content;

    .action-buttons {
      margin: 0;
    }

    .form-foot-link {
      font-size: 12px;
      padding: 0;
      width: fit-content;
    }

    .button {

      &.cancel {
        background-color: transparent;
        border: 1px solid $text-blue;
        color: $text-blue;

        &:active {
          color: $active-blue !important;
          border: 1px solid $active-blue !important;
        }

        &:hover {
          color: $hover-blue !important;
          border: 1px solid $hover-blue !important;
        }
      }
    }
  }

  .applied-filter-contain {
    display: flex;
    flex-wrap: wrap;
    gap: 7px;
    justify-content: center;

    .applied-filter {
      font-size: 12px;
      background-color: $dark-gray;
      padding: 5px;
      border-radius: 5px;
    }
  }
}