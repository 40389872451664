.rlpui-wrap {
  margin: 16px 0;

  .rlpui-form {
    width: unset;
    display: flex;
    gap: 24px;

    .form-block {
      flex: 1;
      background-color: #F9F9F9;
      box-shadow: 0px 1px 4px 0px #00000040;
      border-radius: 10px;

      .form-block-header {
        padding: 11px 16px;
        background-color: $dark-blue;
        color: white;
        height: 40px;
        font-size: 14px;
        font-weight: 600;
        border-radius: 10px 10px 0 0;
      }

      .form-block-body {
        padding: 24px 16px;

        .input.select {
          color: #737373;
        }

        .checkbox {
          color: #737373;

          > label {
            font-size: 14px;
            vertical-align: middle;
          }

          > input {
            cursor: pointer;
            accent-color: $dark-blue;
            vertical-align: middle;
            margin-right: 8px;
            width: 15px;
            height: 15px;
          }
        }

        > .form-group {
          margin: 0;

          .flex-half {
            flex: .4;
          }

          .not-flex {
            align-self: end;

            .form-input, .button {
              display: inline-block;
            }

            .form-input {
              margin-right: 16px;
            }
          }
        }
      }
    }
  }
}