.tab-view {
  margin-top: 30px;

  .tab-navs {
    display: flex;
    gap: 2px;
    margin-bottom: 32px;

    .react-tabs__tab {
      background: transparent;
      border: none;
      border-bottom: 3px solid $xevant-gray;
      display:inline-flex;
      text-align: center;

      a {
        font-size: 14px;
        color:$xevant-gray;
        flex: 1;
        padding: 10px;
      }

      &.react-tabs__tab--selected {
        color: $text-blue;
        border-color: $text-blue;

        a {
          color: $text-blue;
          font-weight: 700;
        }

        &::after {
          display: none;
        }
      }
    }
  }

  .react-tabs__tab-panel {
    .tab-panel-contain {
      padding-top: 20px;

      .resource-blurb{
        margin: 20px 0;
        padding: 5px 0 5px 26px;
        border-left: 7px solid $text-blue;
      }

      .resource-search {
        width: 300px;
        margin-left: auto;
        margin-right: 30px;
      }
    }
  }

}

.embed-course {
  width: 100%;
  height: 80vh;
  padding: 20px 40px;
  max-width:1400px;
}

.course-upload-form {
  max-width: 90%;

  .input.textarea > textarea {
    height: 230px;
  }

//  .form-split-wrap{
//   display:flex;
//   gap: 40px;

//   .form-left, .form-right{
//     flex:1;

//     .input.textarea > textarea{
//       height: 230px;
//     }
//   }
//  }
}

.form-split-wrap {
  display:flex;
  gap: 40px;

  .form-left, .form-right{
    flex:1;

    // .input.textarea > textarea{
      // height: 230px;
    // }
  }
 }