.modal {
  .modal-header {
    background-color: #286D97;
    padding: 16px;
    color: $white;
  }

  .modal-body {
    .sheets-container {
      padding: 16px;

      ::-webkit-scrollbar {
        background-color: $white;
        width: 16px;
      }

      /* background of the scrollbar except button or resizer */
      ::-webkit-scrollbar-track {
        background-color: $white;
      }

      /* scrollbar itself */
      ::-webkit-scrollbar-thumb {
        background-color: #babac0;
        border-radius: 16px;
        border: 4px solid $white;
      }

       /* set button(top and bottom of the scrollbar) */
      ::-webkit-scrollbar-button {
        display:none;
      }

      .sheet-actions {
        display: flex;
        justify-content: space-between;
        align-items: center;
        background-color: $gray-5;


        .sheets-action-radio {
          display: flex;
          justify-content: space-between;
          gap: 24px;

          .button {
            background-color: #286D97;
          }
        }

        .sheets-selected {
          padding-right: 16px;
        }
      }

      .sheet-item-container {
        display: flex;
        justify-content: space-between;
        padding: 16px 32px;
        overflow-x: auto;
        background-color: #FAFAFA;
        scroll-behavior: smooth;
        gap: 16px;

        .sheet-item {
          display: flex;
          align-items: center;
          justify-content: space-between;
          flex-direction: column;
          background-color: #FAFAFA;
          width: 120px;


          .sheet-item-thumbnail {
            width: 110px;
            height: 100px;
            background-color: #EBECED;
            display: flex;
            align-items: flex-end;
            justify-content: flex-start;
            flex-direction: column;
            padding: 8px;
            cursor: pointer;

            .sheet-select-status{
              background-color: white;
              border: 1px solid #286D97;
              color: $white;
              height: 20px;
              width: 20px;
              border-radius: 50%;
              display: flex;
              align-items: center;
              justify-content: center;

              i {
                color: white;
              }

              &.selected {
                background-color: $xevant-green;
                border: 1px solid $xevant-green;
              }
            }
          }

          .sheet-item-name {
            height: 130px;
            overflow: hidden;
            font-size: 14px;
            font-weight: 500;
            text-overflow: ellipsis;
            background-color: #FAFAFA;
            display: flex;
            justify-content: space-between;
            align-items: center;
            flex-direction: column;
            padding: 24px;
            cursor: pointer;

            .sheet-favorite {
              margin-top: 8px;
              display: flex;
              gap: 8px;
              justify-content: center;
              align-items: center;

              .sheet-favorite-button {
                height: 16px;
                width: 16px;
                border-radius: 50%;
                background-color: white;
                border: 1px solid #286D97;
                display: flex;
                align-items: center;
                justify-content: center;

                &.favorite {
                  .sheet-favorite-status {
                    height: 10px;
                    width: 10px;
                    border-radius: 50%;
                    background-color: #7AC35F;
                  }
                }
              }
            }
          }
        }

        .divider {
          width: 3px;
          border: 2px solid white;
        }
      }

      .scroll-right-button {
        position: absolute;
        right: 10px;
        top: 200px;
        cursor: pointer;

        i {
          color: #286D97;
        }
      }

      .scroll-left-button {
        position: absolute;
        left: 10px;
        top: 200px;
        cursor: pointer;

        i {
          color: #286D97;
        }
      }

      .download-button {
        margin-top: 8px;
        margin-left: auto;
        background-color: #528C3E;
        font-weight: 600;
        margin-right: 16px;

        i {
          margin-left: 8px;
        }
      }
    }
  }
}


#plan-review-actions-container {
  display: flex;
  padding-bottom: 16px;

  .container {
    height: 50px;
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: flex-end;

    .form-input {
      flex: 0;
      padding-bottom: 32px;
      width: 75%;

      .input > img {
        max-width: 50px;
      }
    }

    .button {
      width: 75%;
      background-color: #2E5984;
      font-weight: 600;

      i {
        margin-right: 5px;
      }
    }
  }

  .container:last-child {
    align-items: flex-end;
  }
}