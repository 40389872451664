.resolver-page {
  $field-height: 40px;
  font-size: 14px;

  h5 {
    font-size: 13px;
    color: $blue;
    background-color: $white;
    padding: 5px 5px 5px 15px;
  }

  .loader-a {
    margin-top: 100px;
    width: 100%;
    text-align: center;

    > span {
      background: rgba(0,0,0,0.5);
    }
  }

  .action-bar {
    margin: 0;

    > .form-group {
      margin-bottom: 0;
      margin-top: 5px;
    }

    > .download {
      margin-top: 5px;
    }

    .button:disabled {
      background-color: $disabled-gray;
      cursor: no-drop;
    }

    .action-buttons {
      margin-left: auto;
      min-width: calc(33% - 32px);

      > .resolve-button {
        width: 100%;
        border-radius: 5px;
      }
    }

    .back {
      background-color: $red;
    }
  }

  .file-groups-table {
    .folder-icon {
      > i {
        font-size: 36px;
        color: $blue;
      }
    }
  }

  .file-uploads-table {
    .file-icon {
      > i {
        font-size: 36px;
        color: $blue;
      }
    }
  }

  .file-details {
    margin-bottom: 16px;
    border-bottom: 1px solid #eee;
    display: flex;
    flex-direction: row;

    .form-group {
      margin-right: 64px;
    }

    .file-name {
      min-width: 300px;
    }

    .input.dark-blue-bg {
      background-color: $dark-blue;
    }

    .input.red-bg {
      background-color: $red;
    }

    .input.dark-blue-bg, .input.red-bg {
      border: none;
      width: fit-content;

      input {
        color: $white;
        width: auto;
        padding: 0 14px;
      }

      &.bold > input {
        font-weight: 600;
        width: 50px;
        text-align: center;
      }
    }
  }


  .column-resolver {
    display: flex;
    margin-bottom: 32px;

    div[data-rbd-placeholder-context-id] {
      display: none !important;
    }


    .available-column-list {
      margin-right: 50px;

      .available-column-item {
        display: flex;
        width: 400px;
        height: $field-height;
        margin-bottom: 20px;

        > .available-column-name {
          flex: 1;
          display: flex;
          align-items: center;
          background-color: #e2e2e2;
          width: 200px;
          padding: 10px;
        }

        > .available-column-droppable {
          flex: 1;
          display: flex;
          background-color: #2ecc71;
          width: 200px;
        }
      }
    }

    .invalid-column-list {
      .invalid-column-item {
        width: 200px;
        height: $field-height;
        margin-bottom: 20px;
      }
    }


    .column-list {
      position: relative;
      flex: 1;

      > .column-item {
        position: relative;
        display: flex;
        margin-bottom: 8px;

        > .column-name {
          flex: 1;
          display: flex;
          align-items: center;
          background-color: $white;
          color: $hover-blue;
          padding: 0px 15px;
          margin-left: auto;
          height: $field-height;
          border-radius: 5px;
        }

        > .column-drop-area {
          flex: 1;
          align-items: center;
          border: 1px dashed $hover-blue;
          color: $hover-blue;
          height: $field-height;

          &.is-dragging-over {
            background-color: $dark-green;
          }

          &.is-resolved {
            background-color: $hover-blue;
            color: $white;
            border: none;
          }

          > .column-drop-area--column-name {
            display: flex;
            align-items: center;
            padding: 0px 12px 0 8px;
            height: $field-height;
            width: inherit;

            > span {
              position: absolute;
              margin-left: 18px;
            }

            > i {
              font-size: 16px;
            }

            > svg {
              color: $dark-blue;
              font-size: 15px;
            }

            > .remove-item {
              cursor: pointer;
              margin-left: auto;
            }

            &:not(.is-dragging) {
              transform: none !important;
            }

            &.is-dragging {
              background-color: #2ecc71;
              color: $white;
            }
          }
        }
      }
    }

    .available-columns {
      margin-right: 64px;
      width: calc(66% - 32px);
      border: 1px solid $dark-blue;
      border-radius: 5px;
      min-height: 500px;
      max-height: 700px;

      > .column-header {
        background-color: $dark-blue;
        width: 100%;
        padding: 15px;
        color: $white;
        font-weight: 600;
      }

      .column-body {
        display: flex;
        gap: 60px;
        justify-content: center;
        padding: 30px 40px;
        max-height: 800px;
        overflow-y: auto;

        .column-list {
          height: 100%;

          .column-item {
            .column-drop-area {
              border-radius: 5px;
              border-color: $dark-blue;
              cursor: default;

              &.is-resolved {
                background-color: $dark-blue;
              }
            }
          }
        }

        .list-label .column-name {
          color: $dark-blue;
        }
      }
    }

    .invalid-columns {
      margin-left: auto;
      width: calc(33% - 5px);
      border: 1px solid $red;
      border-radius: 5px;
      min-height: 500px;
      max-height: 700px;

      > .column-header {
        background-color: $red;
        width: 100%;
        padding: 15px;
        color: $white;
        font-weight: 600;
      }

      > .column-list {
        position: relative;
        margin-left: auto;
        margin-right: auto;
        padding: 30px 40px;
        max-height: 600px;
        overflow-y: auto;
        scrollbar-gutter: stable;

        &::-webkit-scrollbar {
          width: 0;
        }

        .no-transform {
          transform: translate(0, 58px);
        }

        > .column-item {
          position: relative;
          display: flex;
          margin-bottom: 8px;
          justify-content: center;
          margin-left: auto;
          margin-right: auto;

          > .column-drop-area {
            flex: 1;
            align-items: center;
            border: none;
            background-color: $white;
            color: #737373;
            height: $field-height;
            border-radius: 5px;

            &.is-resolved {
              background-color: transparent;
              border: 1px solid #737373;
              cursor: no-drop;
            }
          }
        }
      }
    }
  }

  .file-upload-form {
    .file-upload-date-group {
      gap: 40px;

      > .form-input {
        > .input.datepicker {
          flex-direction: row-reverse;
          background-color: #eee;

          > i {
            margin: 0 15px 0 13px;
          }

          > input {
            background-color: white;
          }
        }
      }
    }

    .file-upload-toggle-group {
      margin: 40px 0;

      .file-upload-toggle-group-left {
        display: flex;
        flex: 1;

        > .form-input {
          flex: none;
          display: flex;

          > .input-label {
            white-space: nowrap;
            margin-right: 5px;
          }

          > .input.number {
            width: 50px;
            border-radius: 4px;
            height: 28px;
            margin-top: 6px;
            padding-left: 5px;
            border: 1px solid #999;

            &.disabled {
              background-color: #ccc;
            }

            > input {
              width: 100%;
              height: 100%;
              padding: 0;

              &:focus {
                outline: none;
              }
            }
          }
        }
      }

      .file-upload-toggle-group-right {
        justify-content: flex-end;
        display: flex;
        flex: 1;

        > .form-input {
          display: flex;
          flex-direction: row-reverse;
          padding: 0 15px;

          > .input-label {
            white-space: nowrap;
            margin-left: 2px;
          }

          .input.toggle {
            &.disabled {
              > .toggle-input {
                background-color: #ccc;
              }
              .toggler {
                background-color: #ccc;
              }
            }

            .toggle-input {
              border: 1px solid #999;

              .toggler {
                border: 1px solid #999;
                transform: scale(1.05);
              }
            }
          }
        }
      }
    }
  }
}

.file-upload-header-text {
  margin-bottom: 25px;
}

