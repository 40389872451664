#vizWrapperSettings {
  visibility: hidden;
}

.sync-status {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  color: $blue;
  padding: 32px;
  min-height: 200px;

  .form-input {
    min-width: 60%;
  }
}