.page-message {
  padding: 0px 0px 20px 0px;
  font-size: 14px;
}

.left-content {
  .left-content-icon {
    margin: auto;
    text-align: center;
    > i {
      color: $red;
      font-size: 250px;
    }
  }
}

.right-content {
  background-color: $white;
  padding: 25px 60px;
  width: 500px;
  display: flex;
  flex-direction: column;
  vertical-align: middle;

  &.invalid-right-content {
    padding: 25px 50px;
  }

  > h1 {
    color: $red !important;
    margin-top: auto;
  }
  > p:last-child {
    margin-bottom: auto;
  }
}