
.loader-a {
  display: inline-block;
  padding: 0px;
  font-size: 0px;

  span {
    vertical-align: middle;
    border-radius: 100%;
    display: inline-block;
    width: 10px;
    height: 10px;
    margin: 3px 2px;
    animation: loader-a 0.8s linear infinite alternate;

    &:nth-child(1) {
      background: rgba(255, 255, 255, 0.6);
      animation-delay: -1s;
    }

    &:nth-child(2) {
      background: rgba(255, 255, 255, 0.8);
      animation-delay: -0.8s;
    }

    &:nth-child(3) {
      background: rgba(255, 255, 255, 1);
      animation-delay: -0.26666s;
    }

    &:nth-child(4) {
      background:rgba(255, 255, 255, 0.8);
      animation-delay:-0.8s;
    }

    &:nth-child(5) {
      background: rgba(255, 255, 255, 0.4);
      animation-delay: -1s;
    }
  }
}

@keyframes loader-a {
  from {transform: scale(0, 0);}
  to {transform: scale(1, 1);}
}

.loader-circle {
  $circle-size: 18px;

  width: $circle-size;
  height: $circle-size;
  display: inline-block;
  padding: 0px;
  font-size: 0px;
  position: relative;
  margin-right: 10px;

  span {
    background-color: white;
    opacity: 0.5;
    border-radius: 100%;
    position: absolute;
    display: block;
    top: 50%;
    left: 50%;
    width: 2.5px;
    height: 2.5px;
    margin: -2px;
    animation: loader-circle 0.8s linear infinite;

    $item-count: 8;
    $angle: calc(360 / $item-count);
    $rot: 0;

    @for $i from 1 through $item-count {
      &:nth-child(#{$i}) {
        transform:
          rotate($rot * 1deg)
          translate(calc($circle-size / 2))
          rotate($rot * -1deg);
        animation-delay: calc($i * 0.1s);
      }
      $rot: $rot + $angle;
    }
  }
}

@keyframes loader-circle {
  10% {
    opacity: 1;
  }
  // 20% {
    // opacity: 1;
  // }
  30% {
    opacity: 0.5;
  }
}

.loader-circle-lines {
  $circle-size: 14px;

  width: $circle-size;
  height: $circle-size;
  display: inline-block;
  padding: 0px;
  font-size: 0px;
  position: relative;

  span {
    background-color: white;
    opacity: 0.5;
    position: absolute;
    display: block;
    top: 30%;
    left: 50%;
    animation: loader-circle 0.8s linear infinite;
    width: 1.5px;
    height: 5px;
    border-radius: 10px;

    $item-count: 8;
    $angle: calc(360 / $item-count);
    $rot: 0;

    @for $i from 1 through $item-count {
      &:nth-child(#{$i}) {
        transform:
          rotate($rot * 1deg)
          translate(calc($circle-size / 2))
          rotate(90deg);
        animation-delay: calc($i * 0.1s);
      }
      $rot: $rot + $angle;
    }
  }

  &.dark {
    >span {
      background-color: #0E0E20;
    }
  }
}