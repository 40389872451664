.password-validation-wrapper{
  margin-top: -10px;

  .validation-checklist{
    padding-left: 24px;

    >.validation-checklist-item{
      font-size:11px;
      color:$text-red;
      margin: 10px 0;

      &.valid{
        color:$xevant-green;
      }

      >i{
        display:inline-block;
        margin-right: 20px;
      }

      >p{
        display:inline-block;
        color: black;
      }
    }
  }

  .password-validator-div{
    margin-bottom: 10px;

    .pass-val-bars{
      display:flex;
      justify-content: space-between;

      .pass-val-bar{
        background-color: #999;
        height: 5px;
        width: 32%;

        &.poor{
          background-color: $text-red;
        }

        &.avg{
          background-color: $text-yellow;
        }

        &.good{
          background-color: $xevant-green;
        }
      }
    }

    .pass-val-text{
      text-align: right;
      font-size: 11px;
      margin-top: 5px;

      &.poor{
        color: $text-red;
      }
      &.avg{
        color: $text-yellow;
      }
      &.good{
        color: $xevant-green;
      }
    }
  }
}