.resource-list {
  margin: 96px 96px 0px;

  .resource-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    gap: 44px;

    .resource-item {
      flex: 0 1 calc(50% - 88px);
      display: flex;
      align-items: center;
      background-color: $blue;
      border: 1px solid $dark-blue;
      border-radius: 20px;
      display: flex;
      height: 100px;
      max-width: 500px;
      margin-bottom: 2px;
      justify-content: center;
      transition: all 200ms ease-in-out;
      width: 100%;

      &:hover {
        transform: scale(1.1);
      }

      &:active {
        background-color: $active-blue;
        border: 1px solid $active-blue;
      }

      > a {
        align-items: inherit;
        color: $white;
        display: flex;
        flex-direction: row;
        font-weight: bold;
        gap: 34px;
        height: inherit;
        justify-content: inherit;
        width: inherit;
        padding: 21px 84px;

        > i {
          font-size: 32px;
        }

        > span {
          font-size: 24px;
          font-weight: 500;
          text-align: center;
        }
      }
    }
  }
}