.subscription-list-wrap{

  .subscription-mass-edit-wrap{
    display: flex;
    gap: 30px;
    margin-bottom: 10px;
    margin-bottom: -30px;
    padding-left: 2.5%;

    .checkbox:hover{
      background-color: $dark-gray;
      border-radius: 50%;
    }

    input {
      margin: 5px;
      accent-color: $blue;
    }

    .action-button{
      cursor: pointer;
      visibility: hidden;
      > i {
        &.fa-pause {
          color: $text-yellow;
        }
        &.fa-play {
          color: $xevant-green;
        }
      }

    }
    .delete-button{
      color: $red;
    }
  }

  table {
    > tbody > tr > td:first-child {
      width: 6%;
    }

    .action-buttons{
      .action-button{
        &.subscription-pause-button {
          color: $text-yellow;
          cursor: pointer;

          > .fa-play{
            color: $xevant-green;
          }
        }
      }

      .blank-space {
        width: 40px;
      }
    }
  }
}

.subscription-swal-actions > button.swal2-styled {
  height: 35px;
  padding: 8px 16px;
}

.visible-button {
  visibility: visible;
}