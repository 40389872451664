.data-table {
  .data-table-top-bar {
    display: flex;
    font-size: 14px;
    font-weight: bold;
    margin-bottom: 16px;

    > .data-table-left {
      display: flex;
      gap: 12px;

      > .column-filter {
        align-self: center;
        background-color: white;
        cursor: pointer;
        display: flex;
        font-weight: 100;
        justify-content: flex-end;
        width: 300px;

        > label {
          align-self: center;
          flex: 1;
        }

        > select {
          background-color: transparent;
          border: none;
          border-radius: 5px;
          cursor: pointer;
          height: 35px;
        }
      }
    }

    > .data-table-right {
      display: flex;
      gap: 12px;
      margin-left: auto;

      > .global-filter-search {
        background-color: white;
        border: 1px solid $blue;
        border-radius: 5px;
        color: $dark-blue;
        display: flex;
        height: 35px;
        justify-content: space-between;
        padding: 4px 6px;
        width: 430px;

        &:hover {
          border-color: $hover-blue;
        }

        &.blue-border {
          border-color: $active-blue;

          &:hover {
            border-color: $active-blue;
          }
        }

        > input {
          border: none;
          font-style: italic;
          width: 100%;
        }

        > i {
          display: flex;
          flex-direction: column;
          justify-content: center;
        }
      }
    }
  }

  > table {
    background-color: $white;
    border: 0;
    border-collapse: collapse;
    font-size: 14px;
    width: 100%;

    > thead {
      > tr {
        > th {
          background-color: $blue;
          border: 0;
          color: $white;
          font-weight: 600;
          padding: 10px 10px;
        }
      }
    }

    > tbody {
      > tr {
        > td {
          border: 0;
          border-bottom: 1px solid $blue;
          color: $xevant-gray;
          padding: 10px 10px;
          text-align: center;

          &.statusCell {
            > div {
              background-color: #333;
              color: $white;
              border-radius: 20px;
              text-align: center;
              width: fit-content;
              margin: auto;
              padding: 2px 12px;
              font-size: 10px;
            }

            &.done > div {
              background-color: $green;
            }

            &.error > div {
              background-color: $red;
            }

            &.resolve > div {
              background-color: $orange;
            }

            &.processing > div {
              background-color: $light-blue;
            }

            &.received > div {
              background-color: $dark-blue;
            }

            &.seen > div {
              background-color: $orange;
            }

            &.inprocess > div {
              background-color: $light-blue;
            }

            &.disqualified > div {
              background-color: black;
            }

            &.completed > div {
              background-color: $green;
            }

            &.demo > div {
              background-color: $text-yellow;
            }

            &.standard > div {
              background-color: $xevant-green;
            }
          }

          &.aggCell {
            background-color: #f7f7f7;
            border-left: none;
            border-right: none;

            .agg-toggle {
              float: left;
              height:20px;
              text-align: center;
              width: 20px;
            }

            .action-buttons {
              a, div {
                opacity: 0;
              }
            }
          }

          .action-buttons {
            align-items: center;
            display: flex;
            font-size: 16px;
            justify-content: center;
            margin: -5px 0;

            .button {
              border-radius: 5px;
            }

            > .action-button {
              color: $blue;
              display: flex;
              flex-direction: column;
              height: 35px;
              justify-content: center;
              width: 40px;

              > .iconify {
                color: $blue;
              }

              &.delete-button {
                color: $red;
              }

              &.email-button {
                align-items: center;

                > .reset-password {
                  font-size: 24px;
                }
              }

              &:hover {
                cursor: pointer;
                filter: brightness(150%);
              }

            }
          }
        }
      }
    }
  }

  .pagination {
    display: flex;
    flex-direction: row;
    justify-content: left;
    margin-top: 16px;

    .pagination-left {
      align-self: center;
      color: $text-gray;
      flex: 1;
      font-size: 14px;

      > select {
        border-radius: 3px;
        cursor: pointer;
        margin: 0 5px;
      }

      > b {
        font-weight: 400;
      }

      > .page-size-filter {
        align-self: center;
        color: $text-gray;
        display: inline-block;
        font-weight: 400;
        min-width: 140px;

        > .form-input {
          display: inline-block;

          > .table-pagination-select {
            > .dropdown {
              > .dropdown-content {
                .dropdown-item {
                  display: flex;
                  justify-content: center;
                }
              }

              > .dropdown-btn {
                  height: 25px;
                  padding: 0 0 0 4px;
                  width: 40px;

                > .dropdown-icon {
                    right: 4px;
                }
              }
            }
          }
        }
      }
    }

    .pagination-right {
      color: $text-gray;
      font-size: 14px;
    }
  }

  .pagination-bottom {
    display: flex;
    justify-content: flex-end;
    margin-top: 32px;

    > .pagination-buttons {
      > button {
        border: none;
        color: $dark-blue;
        cursor: pointer;
        margin: 0 4px;
        padding: 4px 0;
        width: 30px;
      }

      > button:disabled, button[disabled] {
        color: $disabled-gray;
      }

      > button:disabled:hover {
        cursor: no-drop;
      }

      > button:not([disabled]):hover {
        color: $blue;

        &:hover {
          color: $hover-blue;
        }

        &:active {
          color: $active-blue;
        }
      }

      > .pagination-page-num {
        background-color: $blue;
        color: $white;
        font-size: 14px;
        font-weight: normal;
        margin: 0 10px;
        padding: 0 5px;
        width: 15px;

        &:hover {
          background-color: $hover-blue;
        }

        &:active {
          background-color: $active-blue;
        }
      }
    }
  }

  .table-loader {
    height: 417px;
    padding-top: 175px;
    text-align: center;

    .loader-a > span {
      background-color: $blue;
    }
  }
}


