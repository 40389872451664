.site-role-selector {
  width: 100%;
  border: 1px solid $dark-blue;
  border-radius: 5px;
  height: 40px;
  padding: 10px 20px;
  font-size: 14px;
  color: $dark-blue;
  text-align: center;
  cursor: pointer;

  &:hover {
    border: 1px solid $hover-blue;
    color: $hover-blue;
  }

  &.disabled {
    border: 1px solid #BBB;
    color: #BBB;
    cursor: no-drop;
  }
}

.modal.site-role-selector-modal {
  width: 1100px;

  .content.site-role-selector-content {
    margin: 32px 0;
    padding: 0 32px;

    h2 {
      font-size: 18px;
      color: $dark-blue;
      font-weight: 600;
    }

    .site-role-table {
      margin: 32px 0;
      min-height: 290px;
      max-height: 600px;
      overflow-y: scroll;
      margin-right: -16px;
      padding-right: 16px;
      padding-bottom: 200px;

      .site-role-table-row {
        display: flex;
        padding: 12px 0;
        gap: 40px;

        .col {
          flex: 1;
          align-self: center;

          &.col-head {
            font-weight: 600;
          }
        }

        &.set-all-row {
          margin-bottom: 24px;
        }
      }
    }

    .datalogic-toggle-container {
      flex: 1;
    }
  }
}