.form {
  margin: 0 auto;
  width: 50%;


  &.no-margin {
    margin: 0px;
  }
}

.form-errors {
  margin-bottom: 40px;

  > .form-error {
    background-color: $red;
    margin-bottom: 5px;
    padding: 8px 15px;
    border-radius: 3px;
    font-size: 14px;
    color: $white;
  }
}

.form-group {
  display: flex;
  position: relative;
  margin-bottom: 16px;

  &.notice {
    accent-color: $blue;
    align-items: center;
    font-size: 12px;
    gap: 5px;
    justify-content: center;
    margin-top: 32px;

    > input[type='checkbox'] {
     width: 11px;
    }
  }

  &.right {
    justify-content: flex-end;
  }
}

.input-label {
  margin-bottom: 8px;
  // padding-left: 5px;
  font-size: 14px;
  font-weight: 600;
  color: #232323;
  > span.optional {
    font-weight: 400;
    font-size: 12px;
    font-style: italic;
  }

  &.required:after {
    content: '\2217';
    vertical-align: super;
    line-height: 1px;
    color: $red;
    margin-left: 2px;
  }
}

.form-input {
  flex: 1;
  position: relative;

  &:last-child {
    margin-right: 0px;
  }

  &.margin-right {
    margin-right: 30px;
  }

  > .input {
    display: flex;
    align-items: center;
    position: relative;
    background-color: $white;
    border: 1px solid $blue;
    border-radius: 5px;
    transition: all 250ms ease-in-out;


    &:focus-within {
      border: 1px solid $light-blue;
    }

    &.disabled  {
      border: 1px solid $disabled-gray;
      color: $text-gray;
      cursor: no-drop;
    }

    &.textarea {
      > textarea {
        height: 90px;
        padding: 20px;
        resize: none;
      }

      &.small {
        > textarea {
          height: 35px;
          padding: 20px;
          resize: none;
        }
      }
    }

    &.multiselect {
      display: block;
      font-size: 14px;
      width: 100%;
      height: 40px;
      padding: 0px 20px;
      position: relative;
      overflow: visible;
      cursor: pointer;

      > .multiselect-input {

        > .multiselect-values {
          display: flex;
          align-items: center;
          flex-wrap: wrap;

          > ul {
            display: flex;
            height: 40px;
            flex-wrap: wrap;
            overflow-x: hidden;

            &::-webkit-scrollbar {
              display: none;
            }

            > li {
              display: flex;
              align-items: center;
              height: 40px;
              // height: 30px;
              margin-right: 4px;

              > .text {
                // background-color: $dark;
                background-color: $dark-blue;
                padding: 2px 10px;
                border-radius: 20px;
                font-size: 14px;
                color: $white;
                white-space: nowrap;
                max-width: 240px;
                overflow-x: hidden;
                text-overflow: ellipsis;

                &.disabled {
                  background-color: $disabled-gray;
                }
              }
            }
          }
        }

        > .multiselect-options {
          position: absolute;
          background-color: white;
          width: 100%;
          max-height: 0px;
          box-shadow: 1px 0 0 #e6e6e6, -1px 0 0 #e6e6e6, 0 1px 0 #e6e6e6, 0 -1px 0 #e6e6e6, 0 3px 13px rgba(0, 0, 0, 0.08);
          top: 42px;
          left: 0px;
          overflow-y: auto;
          transition: all 200ms ease-in-out;
          z-index: 1;

          &.collapsed {
            max-height: 180px;
          }

          > ul {
            padding: 0;
            > li {
              display: flex;
              align-items: center;
              padding: 8px 12px;
              user-select: none;

              &.selected {
                > .icon {
                  // color: $green;
                  color: $dark-blue;
                }
              }

              > .icon {
                color: black;
                margin-right: 10px;
                margin-top: 1px;
                font-size: 18px;
                cursor: pointer;
              }

              > .text {
                flex: 1;
                font-size: 14px;
              }

              > button {
                width: 100%;
                background-color: $dark-blue;
              }

              &:hover {
                background-color: $dark-gray;
                cursor: pointer;
              }
            }
          }
        }
      }
    }

    &.file {
      > .file-input {
        display: flex;
        align-items: center;
        width: 100%;
        height: 40px;

        > .file-name {
          position: absolute;
          left: 20px;
          font-size: 14px;
        }

        > input {
          width: 100%;
          height: 100%;
          opacity: 0;
          cursor: pointer;
        }
      }
    }

    &.draganddrop {
      cursor: pointer;

      padding:30px 15px;
      color: $xevant-gray;
      font-size: 14px;
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: center;

      > img {
        max-width: 120px;
        vertical-align: middle;
      }

      > i {
        vertical-align: middle;
      }

      .drag-drop-file-input-right {
        font-weight: 600;
        font-size: 13px;
        display: inline-block;
        margin-left: 30px;
        vertical-align: middle;

        p {
          font-size: 11px;
          font-weight: 400;
        }

        > b {
          color: $blue;
        }

        .filename-wrap {
          p {
            display: inline-block;
            vertical-align: middle;
          }
          span.red-x {
            padding-right: 5px;
            color: $red;
            font-size: 16px;
            vertical-align: middle;
            position: relative;
            z-index: 1;

            &:hover {
              color: red;
            }
          }
        }
      }

      > input {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        opacity: 0;
      }

      &.has-errors {
        border: 1px solid $red;
      }
    }

    &.toggle {
      background-color: transparent;
      width: 100%;
      border-radius: 0px;
      border:none;

      &.has-error {
        border: none;

        > .toggle-input {
          border: 1px solid $red;
        }
      }

      &.small {
        > .toggle-input {
          width: 40px;
          height: 20px;
        }
      }

      &.disabled {
        > .toggle-input {
          cursor: no-drop;

          > .toggler-inner {
            border: 1px solid $disabled-gray;

            > .toggler {
              background-color: $disabled-gray;
            }
          }
        }
      }

      > .toggle-input {
        display: flex;
        background-color: $white;
        // width: 60px;
        width: 55px;
        height: 30px;
        border-radius: 25px;
        cursor: pointer;
        transition: all 200ms ease;

        &.active {
          background-color: #34c158;
          > .toggler-hover {
            left: 12px;
            transition: all 200ms ease;
          }
          > .toggler-inner  {
              background-color: $blue;
              border: 1px solid $blue;
          }
          > .toggler-inner > .toggler {
            left: 50%;
          }
        }

        &:hover {
          > .toggler-hover {
            background-color: $dark-gray;
          }
        }

        > .toggler-hover {
          position: absolute;
          width: 26px;
          height: 26px;
          border-radius: 50%;
          top: -3px;
          left: -4px;
          bottom: 0px;
        }

        > .toggler-inner {
          flex: 1;
          position: relative;
          width: 100%;
          height: 100%;
          border-radius: 25px;
          background-color: $white;
          border: 1px solid $xevant-gray;

          > .toggler {
            position: absolute;
            background-color: $white;
            width: 14px;
            height: 14px;
            border-radius: 50%;
            box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.1);
            transition: all 200ms ease;
            top: 2px;
            left: 2px;
            bottom: 0px;
          }
        }
      }
    }

    &.yes-no {
      background-color: transparent;
      border-radius: 0px;

      &.disabled {
        > .yes-no-input {
          > .answer-input {
            cursor: no-drop;
          }
        }
      }

      &.small {
        > .yes-no-input {
          > .answer-input {
            width: 60px;
            height: 30px;
            font-size: 14px;
          }
        }
      }

      > .yes-no-input {
        display: flex;

        > .answer-input {
          display: flex;
          justify-content: center;
          align-items: center;
          background-color: $white;
          width: 80px;
          height: 40px;
          margin-right: 10px;
          border-radius: 40px;
          user-select: none;
          cursor: pointer;
          font-size: 14px;

          &.active {
            background-color: $green;
            color: $white;
          }
        }
      }
    }

    &.datepicker {
      &.icon-right {
        flex-direction: row-reverse;
        // background-color: #eee;
        cursor: pointer;

        i {
          margin: 0 15px 0 13px;
          margin: 0 10px 0 10px;
        }

        input {
          width:110px;
        }
      }

      &.disabled{
        cursor: no-drop;
      }
    }

    &.has-error {
      border: 1px solid $red;
    }

    > input,
    > select,
    > textarea {
      align-items: center;
      background: none;
      border-color: transparent;
      border-radius: 5px;
      cursor: inherit;
      display: flex;
      flex: 1;
      font-family: inherit;
      font-size: 14px;
      height: 35px;
      padding: 4px 20px;
      width: 100%;

      &::placeholder{
        color: $scroll-bg;
      }
    }


    > select {
      padding: 0px;
    }

    > i {
      margin-left: 20px;
      color: $main;
    }

    .input-overlay {
      // not tested with inputs besides select & multiselect
      background-color: rgba(0,0,0,0.5);
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      text-align: center;
      padding-top: 2px;
    }
  }
}

.form-info {
  flex: 1;
  margin-right: 30px;

  &:last-child {
    margin-right: 0px;
  }


  > .form-info-value {
    flex: 1;
    display: flex;
    align-items: center;
    background-color: $white;
    height: 40px;
    padding: 0px 20px;
    border-radius: 5px;
    font-size: 14px;
    cursor: no-drop;
    border: 1px solid $blue;
    color: $xevant-gray;
  }
}

.form-submit {
  display: flex;
  margin-top: 64px;

  button{
    border-radius:5px;
  }

  &.right {
    justify-content: flex-end;
    &.password-margin-top {
      margin-top: 64px;
    }
    &.password-no-margin-top {
      margin-top: 0;
    }
  }

  &.space-between {
    justify-content: space-between;
  }
}

.form-message {
  margin-bottom: 5px;
  padding: 8px 15px;
  border-radius: 3px;
  font-size: 14px;

  &.success {
    background-color: $green;
    color: $white;
    margin-bottom: 20px;
  }
}


.dropdown {
  width: 100%;
  position: relative;
  font-size: 14px;
  cursor: pointer;
}

.dropdown .dropdown-btn {
  padding: 10px 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 35px;
  position: relative;
}

.dropdown .dropdown-content {
  position: absolute;
  top: 110%;
  left: -.3%;
  width: 100.5%;
  background-color: $white;
  box-shadow: 0 0 10px 5px rgba(0, 0, 0, 0.07);
  z-index: 1;
  max-height: 200px;
  overflow: auto;
}

.dropdown .dropdown-content .dropdown-item{
  padding: 10px 20px;
}

.dropdown .dropdown-content .dropdown-item:hover{
  background-color: $white;
}

.active-dropdown-select {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 0px 12px;

  &.search-multiselect {
    background-color: white;
    padding-top: 5px;
  }
}

.search-input{
  width: 100%;
  height: 35px;
  border: none;
  font-size: 14px;
}

.disabled-dropdown{
  cursor: no-drop;
}

.dropdown-icon {
  position: absolute;
  right: 15px;
}

.input.multiselect.disabled {
  cursor: no-drop;
}
