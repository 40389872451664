
.mfa-content {
  .mfa-info {
    .left-content {
      padding: 50px 70px !important;

      p {
        line-height: 1.5rem;
      }
    }
  }

  .right-form {
    #mfa-header {
      font-size: 30px;
    }

    .mfa-key-group {
      height: 90px;

      > .form-input {
        display: flex;
        flex-direction: column;
        justify-content: center;

        > .input.textarea {
          height: 50px;

          > textarea {
            font-size: 9px;
            font-weight: bold;
            height: auto;
            padding: 8px 2px 0 2px;
            text-align: center;
          }
        }
      }
    }

    .mfa-scan-qr{
      display:flex;
      height: auto;
      justify-content: center;
      margin: 30px 0;

      > img {
        height: 113px;
        width: 113px;
      }
    }

    .scan-verify-text{
      text-align: center;
      font-size: 14px;
      margin-bottom: 20px;
    }

    .mfa-bottom-buttons {
      button.has-error {
        margin-top: 0;
      }
    }

    .form-errors {
      margin-bottom: 10px;
    }



    .form-group.mfa-skip-check {
      justify-content: flex-end;
      justify-content: center;
      font-size: 12px;
      margin-bottom: 0;
      margin-top: -5px;
      gap: 8px;

      &.has-error {
        margin-bottom: 15px !important;
      }

      .checkbox:hover {
        background-color: $dark-gray;
        border-radius: 50%;
      }

      input {
        margin: 5px;
        accent-color: $blue;
      }

      label {
        align-self: center;
      }
    }
  }

  .mfa-code-digits {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;

    > input {
      font-size: 22px;
      width: 100%;
      height: 50px;
      text-align: center;
      background-color: transparent;
      border-radius: 0;
      border: 1px solid #474747;
      letter-spacing: 20px;
      padding-left: 20px;

      &:focus {
        border: 1px solid $xevant-green;
      }
    }
  }
}

.page-mfa {
  flex: 1;
  display: flex;
  overflow: hidden;
  background-size: cover;
  .content{
    max-width: 516px;
    margin: auto;
  }
  .middle {
    display: flex;
    background-color: rgba(255,255,255,0.9);
    box-shadow: 0px 0px 5px rgba(192, 192, 192, 0.75);
    height: 650px;

    .select-form {
      display: flex;
      justify-content: start;
      flex-direction: column;
      padding: 30px 50px 0px 50px;
      background-color: $white;

      .select-p {
        font-size: 14px;
        line-height: 150%;
      }

      button {
        background-color: $blue;
        border-radius: 5px;
        height: 35px;
        font-weight: 600;
        font-size: 16px;

        &:active {
          background-color: $active-blue;
        }

        i {
          margin-right: 5px;
          padding-top: 2px;
        }

        .iconify-centered {
          margin-right: 5px;
        }
      }

    }

  }
  .content-code {
    max-width: 1010px;
    margin: auto;
    background-color: rgba(255,255,255,0.9);
    box-shadow: 0px 0px 5px rgba(192, 192, 192, 0.75);
    display: flex;
    height: 634px;
    overflow: hidden;
    .left-info {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      max-width: 505px;
      .left-content {
        display: flex;
        flex-direction: column;
        padding: 40px 70px 50px 70px;
        align-items: center;
        justify-content: start;
      }
      .copyright {
        font-size: 12px;
        padding: 0 45px 10px;
        text-align: center;
      }
    }
    .right-info {
      max-width: 505px;
      display: flex;
      justify-content: center;
      flex-direction: column;
      padding: 0 50px;
      background-color: $white;
      .mfa-p {
        font-size: 16px !important;
        line-height: 150%;
      }
      .digit-input {
        display: flex;
        justify-content: space-evenly;
        width: auto;
        gap: 10px;
        input{
          font-size:22px;
          width: 50px;
          height: 50px;
          text-align: center;
          background-color: transparent;

          border: 1px solid #474747;
          &:focus{
            border: 1px solid $text-blue;
          }
        }
      }

      .checkbox-remember {
        display: flex;
        justify-content: flex-end;
        justify-content: center;
        font-size: 12px;
        margin-bottom: 0;
        margin-top: 12px;
        gap: 8px;

        .checkbox:hover {
          background-color: $dark-gray;
          border-radius: 50%;
        }

        input {
          margin: 5px;
          accent-color: $blue;
        }

        label{
          align-self: center;
        }
      }
      button {
        margin-top: 35px;
        margin-bottom: 5px
      }
      .resend-code {
        font-size: 12px;
        text-align: center;
        margin-top: 12px;
        span {
          cursor: not-allowed;
          color: $disabled-gray;
          &.clickable {
            color: $text-blue;
            cursor: pointer;
            &:hover {
              filter: brightness(90%);
            }
          }
        }
      }
    }
  }
  h2 {
    color: $text-blue;
  }
  p {
    margin-bottom: 30px;
    color: #434343;
    text-align: justify;
  }
  img {
    max-height: 98px;
    max-width: 90%;
  }

  span.highlight-blue {
    color: $blue
  }
}
