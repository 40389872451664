.user-box-contain {
  position: relative;
  .user-box-circle {
    background-color: $xevant-green;
    border: 1px solid $text-blue;
    border-radius: 50%;
    height: 45px;
    width: 45px;
    color: white;
    text-align: center;
    padding-top: 12px;
    font-weight: 700;
  }

  .user-box-popup {
    background-color: white;
    box-shadow: 0 0 5px rgba(192,192,192,0.75);
    color: $xevant-gray;
    margin-top: 10px;
    max-height: 0;
    overflow: hidden;
    position: absolute;
    transition: max-height .3s;
    top: 62vh;
    width: 250px;
    border-radius: 8px;
    margin-left: 32px;

    &.expand {
      max-height: 450px;
      z-index: 1;
    }

    >.user-box-circle {
      margin: 10px auto;
      width: 45px;
      height: 45px;
      font-size: 16px;
      padding-top: 11px;
    }

    > .user-name {
      text-align: center;
      color: $blue;
      font-size: 16px;
    }

    > .user-email {
      text-align: center;
      font-size: 12px;
      margin-top: 8px;
    }

    > .user-role {
      text-align: center;
      font-size:12px;
      margin-top:8px;
      display: flex;
      justify-content: center;
      p {
        color: $white;
        background-color: $blue;
        width: fit-content;
        padding: 4px 8px 4px 8px;
        border-radius: 5px;
      }
    }

    > .client-name {
      text-align: center;
      font-size: 14px;
      margin-top: 8px;
    }

    >ul {
      margin-top:10px;
      padding: 10px 10px 10px 15px;
      font-size: 12px;
      border-top: 1px solid $text-blue;

      >li {
        margin: 8px 0;

        >a {
          // font-size:14px;
          margin-left:8px;
          padding: 10px 0;
          line-height: 30px;
          color: $xevant-gray;

          > i, .iconify {
            color: $blue;
            vertical-align: middle;
            margin-right: 12px;
            width: 22px;
            font-size: 22px;
          }

          > span {
            vertical-align: middle;
            margin-left: 10px;
          }

          &:hover {
            > span {
              color: $text-blue;
            }
          }

          &.disabled {
            cursor: default;

            >.iconify, >span {
              color: inherit;
            }
          }
        }
      }
    }
  }
}