.tab-view {
  margin-top: 60px;

  .tab-navs {
    display: flex;
    gap: 2px;
    // margin-bottom: 30px;

    .react-tabs__tab {
      background: transparent;
      background-color: transparent;
      border: none;
      display:inline-flex;
      text-align: center;

      a {
        color: $xevant-gray;
        flex: 1;
        font-size: 14px;
        padding: 10px;
        border-bottom: 3px solid $xevant-gray;

        &:hover {
          border-color: $hover-blue;
          color: $hover-blue;
        }

        &:active {
          border-color: $active-blue;
          color: $active-blue;
        }
      }

      &.react-tabs__tab--selected {
        color: $blue;

        a {
          color: $blue;
          font-weight: 700;
          border-color: $blue;

          &:hover {
            border-color: $hover-blue;
            color: $hover-blue;
          }

          &:active {
            border-color: $active-blue;
            color: $active-blue;
          }
        }

        &::after {
          display: none;
        }
      }
    }
  }

  .react-tabs__tab-panel{
    .tab-panel-contain{
      padding-top: 0;

      #resource-blurb-container {
        align-items: center;
        display: flex;

        > .resource-blurb {
          border-left: 7px solid $blue;
          margin: 0;
          padding: 5px 0 5px 26px;
        }

        > .form-input {
          display: flex;
          justify-content: flex-end;

          > .resource-search {
            height: fit-content;
            margin: 0;
            width: 300px;

            &.single {
              margin: 5.5px 0;
            }

            > i {
              padding-right: 6px;
            }
          }
        }
      }

      > .resource-cards-wrapper {
        justify-content: start;

        > .resource-card {
          width: 18%;
          max-width: none;
        }
      }
    }
  }

}

.embed-course {
  height: 80vh;
  max-width:1400px;
  padding: 20px 40px;
  width: 100%;
}

.course-upload-form {
  max-width: 90%;

  .input.textarea > textarea {
    height: 230px;
  }
}

.form-split-wrap {
  display:flex;
  gap: 40px;

  .form-left, .form-right {
    flex:1;
  }
 }