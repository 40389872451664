.alert-list-wrap{
  .alert-mass-edit-wrap{
    display: flex;
    gap: 30px;
    margin-bottom: 10px;
    margin-bottom: -30px;

    .checkbox-container {
      display: flex;
      justify-content: center;
      width: 6%;
      > input {
        accent-color: $main;
      }
    }

    .action-button {
      cursor: pointer;
      > i {
        &.fa-pause {
          color: $text-yellow;
        }
        &.fa-play {
          color: $xevant-green;
        }
      }
    }

    .delete-button {
      color: $red;
    }
  }
  table {
    > tbody > tr > td:first-child {
      width: 6%;

      > div > .checkbox-col > input {
        accent-color: $main;
      }
    }
    .action-buttons{
      .action-button{
        &.alert-pause-button {
          color: $text-yellow;
          cursor: pointer;
          > .fa-play{
            color: $xevant-green;
          }
        }
      }
      .blank-space {
        width: 40px;
      }
    }
  }
}
.alert-swal-actions > button.swal2-styled {
  height: 35px;
  padding: 8px 16px;
}
.visible-button {
  visibility: visible;
}