.modal-backdrop {
  display: none;
  position: fixed;
  background-color: rgba(0, 0, 0, 0.4);
  width: 100%;
  height: 100%;
  top: 0px;
  left: 0px;
  z-index: 999;

  &.show {
    display: flex;
  }

  > .modal {
    position: relative;
    background-color: $white;
    width: 600px;
    height: 50%;
    margin: auto;
    overflow-y: auto;

    > .modal-close {
      position: absolute;
      top: 10px;
      right: 10px;
      color: $text-gray;
      cursor: pointer;
    }

    > .header {
      padding: 10px 20px;
      font-size: 16px;
      border-bottom: 1px solid #eee;
    }

    > .content {
      padding: 10px;
      font-size: 14px;

      > .notice-header {
        color: $blue;
        font-size: 24px;
        font-weight: 600;
        margin: 16px 0;
      }

      > .notice-body {
        font-size: 14px;
        font-weight: 400;
        margin-bottom: 48px;
      }
    }
  }
}