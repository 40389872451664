@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;600;700&display=swap');
@import 'variables.scss';
@import 'common';
@import 'lib';
@import 'wrappers';
@import 'screens';
@import 'shared';
@import 'overides';
