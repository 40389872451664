.page-message {
  padding: 0px 0px 20px 0px;
  font-size: 14px;
}

.change-password-content{
  width: 375px;
  margin: 0 auto;
  padding: 20px 0;

  // .half{
  //   width: 50%;
  //   margin: 20px auto;
  // }

  .new-password{
    >i{
      margin: 0 10px 0 -30px;
      cursor:pointer;
    }
  }
}