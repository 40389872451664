.button {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: $blue;
  height: 35px;
  padding: 0px 30px;
  border: none;
  border-radius: 5px;
  font-family: inherit;
  font-size: 14px;
  font-weight: 400;
  color: $white;
  cursor: pointer;
  min-width: 125px;

  &:focus {
    outline: none;
  }


  &.block {
    width: 100%;
  }

  &.circle {
    border-radius: 50px;
  }

  &.standard {
    padding: 8px 16px;
    height: fit-content;
    min-width: auto;

    &.icon-left {
      > i {
        margin-top: 1px;
        margin-right: 5px;
      }
    }

    &.icon-right {
      > i {
        margin-left: 5px;
      }
    }
  }

  &.primary {
    &:hover:not([disabled]) {
      background-color: $hover-blue;
    }

    &:active:not([disabled]) {
      background-color: $active-blue;
    }

    &:disabled {
      cursor: not-allowed;
      background-color:  $disabled-gray;
    }
  }

  &.secondary {
    color: $blue;
    border: 1px solid $blue;
    background-color: transparent;

    &:hover:not([disabled]) {
      color: $hover-blue;
      border: 1px solid $hover-blue;
    }

    &:active:not([disabled]) {
      color: $active-blue;
      border: 1px solid $active-blue;
    }

    &:disabled {
      cursor: not-allowed;
      color: $disabled-gray;
      border: 1px solid  $disabled-gray;
    }

  }

  &.tertiary {
    color: $blue;
    border: none;
    background-color: transparent;

    &:hover:not([disabled]) {
      color: $hover-blue;
    }

    &:active:not([disabled]) {
      color: $active-blue;
    }
  }

  &.small {
    height: 35px;
    font-size: 14px;
    padding: 0px 20px;

    &.icon-left {
      > i {
        margin-top: 1px;
        margin-right: 5px;
      }
    }

    &.icon-right {
      > i {
        margin-left: 5px;
      }
    }
  }

  &.red {
    background-color: $red;
  }

  &.gradient-red {
    background-image: linear-gradient(90deg, #fb2661, #e06c7c);
  }

  &.green {
    background-image: linear-gradient(90deg, #2ecc71, #36c171);
  }

  &.dark {
    background: #34495e;
  }

  &.white {
    background: $white;
    color: $dark;
  }

  &.disabled {
    background-color: #ecf0f1;
    color: $dark;
    cursor: no-drop;
  }

  &.gray {
    background-color: $gray;
  }

  &.icon-left {
    > i {
      margin-right: 10px;
    }
  }

  &.icon-right {
    > i {
      margin-left: 10px;
    }
  }

  &.bordered-white {
    border: 1px solid rgba(255, 255, 255, 0.5);
  }

  &.full-width{
    width:100%;
  }

  &.form-button {
    width:100%;
  }

  &.back-button {
    background-color: transparent;
    color: $blue;
    font-size: 14px;
    padding: 0 10px 0 0;
  }
}
