.loadOverlayWrap {
  background-color: rgba(0,0,0,0.5);
  position: absolute;
  top: 0;
  left: 0;
  // left: 88px;
  height: 100vh;
  width: 100vw;

  .loaderWrap {

    .progressBarLoader {
      margin: 30% auto;
      background-color: #D9D9D9;
      width: 600px;
      height: 30px;
      border-radius: 30px;
      padding: 3px;

      .progressBarInner {
        background-color: $dark-blue;
        height: 100%;
        border-radius: 30px;
        min-width: 5%;
        transition-duration: .2s;
        // width: 100%;
        // width: 99%;
        width: 5%;

        animation: progress-bar 20s linear;

        @keyframes progress-bar {
          // 0% {width: 5%}
          // 2% {width: 40%}
          // 5% {width: 60%}
          // 7% {width: 60%}
          // 25% {width: 89%}
          // 50% {width: 90%}
          // 80% {width: 93%}
          // 90% {width: 95%}
          // 100% {width:99%}
          0% {min-width: 5%}
          2% {min-width: 40%}
          5% {min-width: 60%}
          25% {min-width: 89%}
          50% {min-width: 90%}
          90% {min-width: 95%}
          100% {min-width: 99%}
        }
      }
    }
  }
}

.preloadOverlayWrap {
  background-color: rgba(255,255,255,0.99);
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;

  .preloaderWrap {
    position: absolute;
    top: 120px;
    left: 48%;

    .preloaderCircle {
      width: 50px;
      height: 50px;
      background-color: $dark-blue;
      border-radius: 200px;

      span {
        content: '';
        position: absolute;
        display: block;
        width: 200px;
        height: 200px;
        box-sizing: border-box;
        margin: -75px;
        border-radius: 200px;
        background-color: $dark-blue;
        animation: pulse-ring 2s ease-out infinite;
        -webkit-animation: pulse-ring 2s ease-out infinite;

        &:nth-child(2) {
          animation-delay: -.6s;
        }

        @keyframes pulse-ring {
          0% {
            transform: scale(.25);
          }
          60%, 100% {
            opacity: 0;
            transform: scale(1);
          }
        }

        @-webkit-keyframes pulse-ring {
          0% {
            opacity: 1;
            -webkit-transform: scale(.25);
          }
          60%, 100% {
            opacity: 0;
            -webkit-transform: scale(1);
          }
        }
      }
    }
  }
}

.componentOverlayWrap {
  // background-color: rgba(243,243,249,0.99);
  display: flex;
  position: absolute;
  left: 0;
  height: -webkit-fill-available;
  width: 100%;
  z-index: 1;

  .componentWrap {
    position: relative;
    top: 120px;
    left: 48%;

    .componentCircle {
      width: 50px;
      height: 50px;
      background-color: $dark-blue;
      border-radius: 200px;

      span {
        content: '';
        position: absolute;
        display: block;
        width: 200px;
        height: 200px;
        box-sizing: border-box;
        margin: -75px;
        border-radius: 200px;
        background-color: $dark-blue;
        animation: pulse-ring 2s ease-out infinite;
        -webkit-animation: pulse-ring 2s ease-out infinite;

        &:nth-child(2) {
          animation-delay: -.6s;
        }

        @keyframes pulse-ring {
          0% {
            transform: scale(.25);
          }
          60%, 100% {
            opacity: 0;
            transform: scale(1);
          }
        }

        @-webkit-keyframes pulse-ring {
          0% {
            opacity: 1;
            -webkit-transform: scale(.25);
          }
          60%, 100% {
            opacity: 0;
            -webkit-transform: scale(1);
          }
        }
      }
    }
  }
}