.client-name-container {
  height: 24px;
  border-radius: 5px;
  border: 1px solid $blue;
  font-size: 12px;
  margin-left: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 120px;
  color: $xevant-gray;
}


.site-switcher-input {
  border-radius: 5px;
  border: 1px solid $blue;
  min-width: 120px;
  margin-left: auto;
  height: 24px;
  font-size: 12px;

  > select {
    height: 24px;
    text-align: center;
    font-size: 12px;
  }

  &:hover {
    cursor: pointer;
  }

  > .dropdown {
    font-size: 12px;
    color: $xevant-gray;

    > .dropdown-content {
      max-height: 175px;
    }

    > .active-dropdown-select {

      > input {
        height: auto;
        border: none;
      }
    }

    > .dropdown-btn {
      gap: 8px;
      > .dropdown-icon {
        color: $blue;
        font-size: 12px;
      }
    }
  }

}

> ul {
  margin-top:10px;
  padding: 10px 10px 10px 15px;
  font-size: 12px;
  border-top: 1px solid $text-blue;

  >li {
    margin: 8px 0;

    >a {
      // font-size:14px;
      margin-left:8px;
      padding: 10px 0;
      line-height: 30px;
      color: $xevant-gray;

      > i, .iconify {
        color: $blue;
        vertical-align: middle;
        margin-right: 12px;
        width: 22px;
        font-size: 22px;
      }

      > span {
        vertical-align: middle;
        margin-left: 10px;
      }

      &:hover {
        > span {
          color: $text-blue;
        }
      }

      &.disabled {
        cursor: default;

        >.iconify, >span {
          color: inherit;
        }
      }
    }
  }
}