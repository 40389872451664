.dashboard-execution-filters-wrap {
  background-color: #F9F9F9;
  border-radius: 3px;
  box-shadow: 0 1px 3px rgba(0,0,0,0.25);
  padding: 20px;
  margin-bottom: 24px;

  &.loading {
    min-height: 249px;
    background-color: rgba(0,0,0,0.25);
    text-align: center;
    padding-top: 100px;
  }

  .dashboard-execution-form {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    justify-content: flex-end;

    .form-errors {
      width: 100%;
    }

    .date-field-wrap {
      flex: 20%;
    }

    .form-input {
      flex: 45%;

      .input {
        > i {
          position: absolute;
          pointer-events: none;
        }
      }
    }

    .form-submit {
      justify-content: flex-end;
      margin-top: 10px;
      gap: 20px;
      width: 25%;
      padding-left: 15px;

      .button {
        height: auto;
        padding: 8px 0;
        flex: 1;
        min-width: unset;
      }
    }
  }
}