$main: #053757;

$blue: #053757;
$dark-blue: #053757;
$light-blue: #34b7f1;
$text-blue: #125E90;
$active-blue: #2879BB;
$hover-blue: #2E5984;
$blue-50: #2196F3;
$blue-1: #F1F9FF;

$bl-blue: #125E90;

$black: #000000;
$gray: #f1f1f1;
$dark-gray: #eaeaea;
$dark: #2c3e50;
$disabled-gray: #bbbbbb;
$icon-gray: #989898;
$light-gray: #f3f3f9;
$text-gray: #808080;
$gray-5: #FAFAFA;
$gray-70: #616161;
$gray-90: #212121;


$green: #25d366;
$dark-green: #27ae60;
$xevant-green: #67BB42;

$xevant-gray: #737373;
$scroll-bg: #c0c0c0;
$scroll-border: #b0b0b0;
$scroll-hover: #a0a0a0;

$orange: #e67e22;

$red: #AD343E;
$light-red: #f5c6cb;
$text-red: #AD343E;

$white: #ffffff;

$yellow: #f1c40f;
$text-yellow: #F49D37;

$gradient-blue: linear-gradient(140deg,#15c39a,#336CAF 60%);
$gradient-light-blue: linear-gradient(315deg, #20bf55 0%, #01baef 74%);
$gradient-light-red: linear-gradient(315deg, #fc9842 0%, #fe5f75 74%);
$gradient-dark: linear-gradient(315deg, #485461 0%, #28313b 74%);

$main-font: 'Open Sans';
$inputBackground: #fff;
$labelColor: #808080;
