.login > .content > .middle.tos-mid {
  padding: 0;

  .left-info {
    background-color: $white;
    width: 400px;

    .login-logo-left {
      margin: 10px;
      height: 70px;
    }
    .left-content {
      height: 540px;
      line-height: 16px;
      overflow-y: auto;
      padding: 10px 40px;

      h5 {
        font-size: 14px;
        font-weight: 600;
        margin: 0;
        color: $dark-blue;
        cursor: pointer;

        &.topMargin {
          margin-top: 15px;
        }
      }

      a {
        display: block;
        margin: 7px 0;
        font-size: 12px;

        &:hover {
          color: $hover-blue;
         }

         &.active {
           font-weight: 600;
         }
      }
    }
  }
  .right-form {
    background-color: transparent;
    width: 600px;
    padding: 25px 25px 25px 35px;

    > #right-form-header {
      display: flex;
      justify-content: flex-end;
      margin-bottom: 10px;

      > h1 {
        margin: 0;
      }

      > span {
        align-self: center;
        background-color: $blue;
        border-radius: 5px;
        color: $white;
        font-size: 12px;
        height: fit-content;
        padding: 8px 16px;
      }
    }
    .doc-box {
      height: 470px;
      overflow-y: scroll;
      font-size: 12px;
      line-height: 20px;
      text-align: justify;
      padding-right: 10px;
      padding-bottom: 200px;
      color: $xevant-gray;
      position: relative;

      > h1 {
        color: $blue;
        font-size: 28px;
        margin-top: 20px;
      }
      > h2 {
        color: $xevant-gray;
        font-size: 15px;
      }

      > p {
        margin-bottom: 20px;
      }

      ul {
        list-style-type: disc;

        li {
          margin:8px 0 8px 20px;
        }
      }

      .doc-link {
        color: $blue;
        cursor: pointer;
        text-decoration: underline;
      }

      .section-break {
        background-color: $white;
        margin-bottom: 20px;
        text-align: center;
      }
    }

    .form-group {
      gap: 8px;
      justify-content: center;
      margin-bottom: 0;
      padding-top: 10px;


      label {
        font-size: 10px;
        color: $xevant-gray;
        line-height: 20px;
      }

      input {
        cursor: pointer;
      }

      .checkbox-agreement {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 8px;

        .checkbox:hover {
          background-color: $dark-gray;
          border-radius: 50%;
        }

        input[type=checkbox]:enabled {
          margin: 5px;
          accent-color: $blue;
        }
      }
    }

    .form-submit.center {
      gap: 12px;
      justify-content: center;
      margin-top: 0;

      .button {
        margin-top: 5px;
        font-weight: 600;
        width: 125px;

        &.decline {
          background-color: transparent;
          color: $blue;
          border: 1px solid $blue;
        }

        &:disabled {
          opacity: .8;
          cursor: no-drop;
        }
      }
    }
  }
}